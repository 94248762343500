<template>
    <div class="ma-10">
      <Loading v-if="isLoading" />
      <Modal v-if="isModal" @close="() => (isModal = false)" width="480px">
        <h3>Konfirmasi Hapus Data</h3>
        <p>Apakah kamu yakin ingin menghapus data Leads ini?</p>
        <div style="display: flex; gap: 20px; margin-top: 30px">
          <Button
            name="BATALKAN"
            width="100%"
            outline
            @click="() => (isModal = false)"
          />
          <Button name="YA, HAPUS" width="100%" @click="onDelete" />
        </div>
      </Modal>
      <Modal
        v-if="isModalDetail"
        @close="() => (isModalDetail = false)"
        width="40%"
      >
        <div class="title-container line-under">
          <DocBlue />
          <h2 class="modal-title me-3" style="font-weight: bolder">
            Detail Data
          </h2>
        </div>
        <div class="modal-body">
          <div class="data-list line-under">
            <div class="detail-data">
              <p class="detail-label">Applicant ID</p>
              <p class="detail-value">{{ applicantDetail.id }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Waktu Data Masuk</p>
              <p class="detail-value">
                {{
                  $helpers.format_date(
                    applicantDetail.created_at,
                    "DD/MM/YYYY hh:mm"
                  )
                }}
                WIB
              </p>
            </div>
          </div>
          <h4 class="mt-5">Personal Data</h4>
          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">Nama Lengkap</p>
              <p class="detail-value">{{ applicantDetail.full_name }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Email</p>
              <p class="detail-value">{{ applicantDetail.email }}</p>
            </div>
          </div>
          
          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">No. Whatsapp</p>
              <p class="detail-value">
                {{ $helpers.phoneFormat(applicantDetail.phone_number) }}
              </p>
            </div>
          </div>
          
          
          <h4 class="mt-5">Pertanyaan</h4>
          <div class="data-list line-under" style="display: block;">
            <div v-for="(data, idx) in applicantDetail.question_answer" :key="idx" class="detail-data" style="margin-top: 20px;"> 
                <b>{{idx+1}}. {{ data.question }}</b>
              <p class="detail-value pt-2">
                 {{ data.answer }}
              </p>
            </div>
          </div>
          <h4 class="mt-5">Status</h4>
          <div class="data-list">
            <p class="detail-value status-text">
              {{ $helpers.nameApplicantList(applicantDetail.status) }}
            </p>
          </div>
        </div>
      </Modal>
      <SideModal v-if="isModalSide">
        <div
          class="title-container line-under"
          style="justify-content: space-between"
        >
          <div class="title-container" style="width: 90%; padding: 0">
            <DocPencil />
            <h2 class="modal-title me-3" style="font-weight: bolder">
              Ubah Data
            </h2>
          </div>
          <div @click="onCloseModal" class="cursor-pointer">
            <CloseIc />
          </div>
        </div>
        <div class="career-detail-modal">
          <div class="career-detail-item-modal">
            <h3 class="mb-5">Personal Data</h3>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Nama Lengkap <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.full_name"
                  outlined
                  placeholder="Masukkan Nama Lengkap Anda"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >Email <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  v-model="form.email"
                  outlined
                  placeholder="Masukkan Email Anda"
                  hide-details
                />
              </div>
            </div>
            <div class="form-ctn">
              <div class="form-ctn-left">
                <label class="form-label"
                  >No. Whatsapp <span style="color: #c63c38">*</span></label
                >
              </div>
              <div class="form-ctn-right">
                <v-text-field
                  class="form-input text-form"
                  type="number"
                  v-model="form.phone_number"
                  outlined
                  placeholder="Misal: 0813-xxxxx-xxxx"
                  hide-details
                />
              </div>
            </div>
          </div>
          <div style="margin-top: 40px">
            <h3>Informasi Lain</h3>
            <v-textarea
              outlined
              v-model="form.notes"
              class="mt-2 rounded-sm"
              hide-details=""
              placeholder="Tulis catatan tambahan di sini..."
              rows="4"
            ></v-textarea>
          </div>
          <div class="line-under mt-10"></div>
          <div class="mt-6">
            <v-select
              outlined
              color="#ACAFBF"
              placeholder="Status"
              v-model="form.status"
              :items="$helpers.statusStatusCuration()"
              :item-text="'name'"
              :item-value="'value'"
              style="border-radius: 10px !important; background-color: white"
              hide-details
            />
          </div>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              gap: 10px;
              margin-top: 100px;
            "
          >
            <Button
              name="BATALKAN"
              outline
              width="150px"
              height="45px"
              @click="() => onCloseModal()"
            />
            <Button
              name="SIMPAN"
              width="150px"
              height="45px"
              @click="() => onSubmitApp()"
            />
          </div>
        </div>
      </SideModal>
      <div class="page">
        <h1
          class="text-page"
          style="
            margin-right: 10px;
            font-size: 28px;
            color: #36373f;
            font-weight: #36373f;
          "
        >
          List Curation
        </h1>
        <button @click.prevent="exportToExcel" class="ml-3 btn-download">
          <DocumentDownload :fill="'#162ca2'" :width="20" :height="20" />
          Unduh Data
        </button>
      </div>
      <v-data-table
        :headers="headers"
        :items="careerApplication"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row gap="4">
              <v-col cols="4">
                <div class="action-head">
                  <div class="f-search">
                    <div class="f-search-ic">
                      <img src="/img/icons/search.png" alt="Search Ic" />
                    </div>
                    <div style="width: 90%">
                      <v-text-field
                        v-model="filter.fullName"
                        type="text"
                        class="f-input"
                        placeholder="Cari nama"
                        dense
                        flat
                        solo
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="3">
                <date-picker
                  class="my-datepicker"
                  v-model="filter.date"
                  placeholder="Tanggal dibuat"
                  valueType="format"
                  range
                ></date-picker>
              </v-col>
              <v-col cols="3">
                <v-select
                  outlined
                  color="#ACAFBF"
                  placeholder="Status"
                  v-model="filter.status"
                  :items="$helpers.statusStatusCuration()"
                  :item-text="'name'"
                  :item-value="'value'"
                  style="border-radius: 10px !important; background-color: white"
                  hide-details
                />
              </v-col>
  
              <v-col cols="2">
                <Button
                  name="TERAPKAN"
                  width="100%"
                  height="54px"
                  @click="() => getCareerApplication()"
                />
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span
            >{{
              $helpers.format_date(item.created_at, "DD/MM/YYYY hh:mm")
            }}
            WIB</span
          >
        </template>
        <template v-slot:item.no="{ item, index }">
          <span>{{ getRowNumber(index) }}</span>
        </template>
        <template v-slot:item.phone_number="{ item }">
          <span>{{ $helpers.phoneFormat(item.phone_number) }}</span>
        </template>
        
        <template v-slot:item.status="{ item }">
          <span>{{ $helpers.nameApplicantList(item.status) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="display: flex; gap: 7px; align-items: center">
            <div class="btn-stoke-blue" @click="watchItem(item)">
              <span>Lihat</span>
            </div>
            <div class="btn-stoke-blue" @click="updateItem(item)">
              <span>Ubah</span>
            </div>
            <div class="btn-stoke-blue" @click="deleteItem(item)">
              <span>Hapus</span>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-row justify="center" v-if="total">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getCareerApplication"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import Modal from "@/components/Modal.vue";
  import SideModal from "@/components/SideModal.vue";
  import Button from "@/components/Button.vue";
  import DocBlue from "@/components/icons/DocBlue.vue";
  import DatePicker from "vue2-datepicker";
  
  import Loading from "@/components/ilustration/Loading.vue";
  import getAuthentification from "@/utils/badrequest.js";
  import DocPencil from "@/components/icons/DocPencil.vue";
  import CloseIc from "@/components/icons/Close.vue";
  import {  utils, writeFile } from 'xlsx';
  import DocumentDownload from "@/components/icons/DocumentDownload.vue";

  export default {
    components: {
      DocBlue,
      DocPencil,
      CloseIc,
      SideModal,
      DatePicker,
      Loading,
      Modal,
      Button,
      DocumentDownload
    },
    data() {
      return {
        isModal: false,
        currentPage: 1,
        isModalDetail: false,
        isHistory: false,
        isLoading: false,
        total: 0,
        search: "",
        perPage: 10,
        id: 0,
        isModeEvent: false,
        careerDetail: null,
        isModalSide: false,
        resume_cv: null,
        portfolio_cv: null,
        applicantDetail: {
          career_id: "",
          full_name: "",
          email: "",
          phone_number: "",
          linkedin_url: "",
          resume_url: "",
          portfolio_url: "",
          location: "",
          status: "",
          current_company: "",
          current_level: "",
          website: "",
          notes: "",
        },
        form: {
          career_id: "",
          full_name: "",
          email: "",
          phone_number: "",
          linkedin_url: "",
          resume_url: "",
          portfolio_url: "",
          location: "",
          current_company: "",
          current_level: "",
          website: "",
          notes: "",
        },
        statuses: [
          {
            text: "Terkirim",
            value: "SUBMITTED",
          },
          {
            text: "Di Proses",
            value: "PROCESS",
          },
          {
            text: "Di Terima",
            value: "APPROVE",
          },
          {
            text: "Di Tolak",
            value: "REJECT",
          },
        ],
        headers: [
          { text: "No", value: "no", width: 50, sortable: false },
          {
            text: "Waktu Data Masuk",
            value: "created_at",
            width: 190,
            sortable: false,
          },
          {
            text: "Nama",
            value: "full_name",
            width: 270,
            sortable: false,
          },
          { text: "Email", value: "email", width: 180, sortable: false },
          {
            text: "No. Whatsapp",
            value: "phone_number",
            width: 180,
            sortable: false,
          },
          
          { text: "Status", value: "status", width: 150, sortable: false },
          { text: "ACTIONS", value: "actions", width: 250, sortable: false },
        ],
        headerExcelData: [
          {
            label: "Nama Lengkap",
            field: "full_name",
          },
          {
            label: "Email",
            field: "email",
          },
          {
            label: "No.Telp",
            field: "phone_number",
          },
          {
            label: "Linkedin URL",
            field: "linkedin_url",
          },
          {
            label: "Resume URL",
            field: "resume_url",
          },
          {
            label: "Portfolio URL",
            field: "portfolio_url",
          },
          {
            label: "Date applied",
            field: "created_at",
          },
          {
            label: "Alamat",
            field: "location",
          },
          {
            label: "Perusahan Saatini",
            field: "current_company",
          },
          {
            label: "Posisi sebelumnya",
            field: "current_level",
          },
          {
            label: "Status",
            field: "status",
          },
          {
            label: "Catatan",
            field: "notes",
          },
        ],
        filter: {
          fullName: "",
          date: "",
          startDate: "",
          endDate: "",
          status: "",
        },
        isFormEdit: false,
        careerApplicationExcel: [],
        careerApplication: [],
      };
    },
    methods: {
      async getCareerApplication() {
        this.filter.startDate = this.filter.date[0] || "";
        this.filter.endDate = this.filter.date[1] || "";
        try {
          const response = await this.$crm_http.get(
            `/v1/career-application?name=${this.filter.fullName}&start_date=${this.filter.startDate}&end_date=${this.filter.endDate}&status=${this.filter.status}&career_id=${this.$route.params.id}&page=${this.currentPage}&per_page=${this.perPage}`
          );
          this.careerApplication = response.data.data.career_applications;
          const t = parseInt(response.data.data.total);
          this.total = Math.floor(t / 10 + (t % 10 === 0 ? 0 : 1));
        } catch (err) {
          console.error(err.response);
        }
      },
      getRowNumber(rowIndex) {
        return (this.currentPage - 1) * this.perPage + rowIndex + 1;
      },
      exportToExcel() {
      const formattedData = this.careerApplication.map(item => {
        const row = {
          full_name: item.full_name,
          email: item.email,
          phone_number: item.phone_number
        };
        item.question_answer.forEach(qa => {
          row[qa.question] = qa.answer;
        });
        return row;
      });

      const worksheet = utils.json_to_sheet(formattedData);
      const workbook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
      
      writeFile(workbook, `data_feedback-page-${this.currentPage}.xlsx`);
    },
      onCloseModal() {
        this.isModalSide = false;
        this.form = {
          career_id: "",
          full_name: "",
          email: "",
          phone_number: "",
          linkedin_url: "",
          resume_url: "",
          portfolio_url: "",
          location: "",
          current_company: "",
          current_level: "",
          website: "",
          notes: "",
        };
      },
      onAddApp() {
        this.isModalSide = true;
        this.isFormEdit = false;
      },
      async getCareerDetail() {
        if (this.$route.query.history) this.isHistory = true;
        await this.$crm_http
          .get(`/v1/career/${this.$route.params.id}`)
          .then((response) => {
            this.careerDetail = response.data.data.career;
            const options = { year: "numeric", month: "long", day: "numeric" };
            const formatter = new Intl.DateTimeFormat("id-ID", options);
  
            const startDate = new Date(this.careerDetail.started_at);
            this.careerDetail.started_at = formatter.format(startDate);
  
            const closedAt = new Date(this.careerDetail.closed_at);
            this.careerDetail.closed_at = formatter.format(closedAt);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            this.$router.push("/not-found");
          });
      },
      async importExcelData() {
        try {
          const response = await this.$crm_http.get(
            `/v1/career-application?career_id=${this.$route.params.id}&page=0&per_page=100`
          );
          this.careerApplicationExcel = response.data.data.career_applications;
        } catch (err) {
          console.error(err.response);
        }
      },
      selectedColor(status) {
        if (status === "SUBMITTED") return "blue";
        if (status === "PROCESS") return "yellow";
        if (status === "APPROVE") return "green";
        return "red";
      },
      deleteItem(item) {
        this.isModal = true;
        this.id = item.id;
      },
      watchItem(item) {
        this.applicantDetail = item;
        this.isModalDetail = true;
      },
      updateItem(item) {
        this.isFormEdit = true;
        this.form = item;
        this.isModalSide = true;
      },
      
      async onDelete() {
        this.isLoading = true;
        try {
          await this.$crm_http.delete("/v1/career-application/" + this.id);
          this.isModal = false;
          this.isLoading = false;
          this.getCareerApplication();
        } catch {
          this.isLoading = false;
        }
      },
    },
    beforeCreate() {
      getAuthentification();
    },
    mounted() {
      this.getCareerApplication();
    },
  };
  </script>
  
  <style scoped>
  .text-page {
    font-weight: bold;
    font-size: 16px;
    color: #162ca2;
  }
  .color-text-blue {
    color: #4456b4;
  }
  .color-text-gray {
    color: #acafbf;
  }
  .page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .btn-download {
    background-color: #ffffff;
    color: #162ca2 !important;
    width: 156px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #162ca2;
  }
  .action-head {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 19px;
  }
  
  .f-search {
    border: 1px solid #d3d6e1;
    width: 350px;
    height: 45px;
    gap: 10px;
    display: flex;
    border-radius: 14px;
  }
  
  .f-search-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin-left: 10px;
  }
  
  .f-input {
    width: 86%;
    background: transparent;
    border: none;
  }
  
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  
  .header-info {
    margin: auto;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
  }
  
  .header-left {
    display: flex;
  }
  
  .career-detail {
    margin: auto;
    padding: 80px 0;
    max-width: 900px;
    width: 100%;
  }
  
  .career-detail-item {
    margin-top: 30px;
  }
  
  .career-detail-item h1 {
    font-size: 26px;
  }
  
  .career-top {
    padding-top: 10px;
    display: flex;
    font-size: 16px;
    gap: 8px;
    align-items: center;
  }
  
  .career-info {
    color: #36373f;
  }
  
  .career-info-ctn {
    display: flex;
    gap: 2px;
    flex-direction: column;
    margin: 20px 0;
  }
  
  .career-title {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #36373f !important;
  }
  
  .information {
    padding-top: 20px;
  }
  
  .rounded-button {
    background-color: #f5f6f9;
    color: #36373f;
    border: 1px solid #162ca2;
    border-radius: 20px;
    /* Adjust the radius as needed */
    padding: 6px 20px;
    margin-right: 10px;
    /* Adjust spacing between buttons */
    display: flex;
    align-items: center;
  }
  
  .yellow-text {
    font-weight: 600;
    cursor: pointer;
    color: #162ca2;
    /* Apply yellow color to "Karir" text */
  }
  
  .icon {
    margin-bottom: -6px;
  }
  
  .header-right {
    display: flex;
    width: 30%;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
  }
  
  .header-right img {
    width: 100%;
    height: 100%;
  }
  
  .alert-info {
    position: fixed;
    width: 150px;
    padding: 10px;
    display: flex;
    justify-content: center;
    z-index: 99;
    top: 85px;
    right: 45px;
  }
  
  .ic-copy {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: 400 !important;
    font-size: 17px;
    border: 1px solid #7b7e8c;
    padding: 15px;
  }
  
  .career-data .label {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    margin: 15px 0;
  }
  
  .career-data .label div {
    background-color: #d3d6e1;
    padding: 5px 10px;
    border-radius: 30px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .career-data .label div p {
    font-size: 14px !important;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-top: 2px;
    color: #36373f;
  }
  
  .btn-stoke-blue {
    border: 1px solid #acafbf;
    border-radius: 12px;
    color: #162ca2;
    font-weight: 600;
    cursor: pointer;
    gap: 10px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 13px;
    background-color: white;
    margin: 5px;
  }
  
  .btn-stoke-blue span {
    font-family: "";
    font-weight: normal !important;
    font-size: 13px;
    min-width: max-content;
  }
  
  .link-cls {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .link-cls:hover {
    color: darkblue;
  }
  
  .info-icon-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .line-under {
    border-bottom: 1px solid #acafbf;
  }
  
  .title-container {
    padding: 20px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .tooltip {
    visibility: hidden;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }
  
  .info-icon-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .modal-body {
    text-align: left;
  }
  
  .data-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    padding: 20px 0;
  }
  
  .detail-data {
    width: 100%;
    text-align: left;
    border-radius: 10px;
    height: min-content;
  }
  
  .detail-label {
    color: #c3c3c6;
    font-size: small;
    margin: 0 !important;
  }
  
  .detail-value {
    margin: 0 !important;
    font-size: 15px;
  }
  
  .status-text {
    font-weight: bold !important;
    color: #162ca2 !important;
  }
  
  .action-head {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .my-datepicker {
    width: 100%;
  }
  
  .my-datepicker ::v-deep .mx-input {
    height: 54px !important;
    border-radius: 12px !important;
  }
  
  .f-search {
    border: 1px solid #d3d6e1;
    width: 100%;
    height: 55px;
    gap: 10px;
    display: flex;
    border-radius: 10px;
    background-color: white;
    padding-left: 20px;
    margin-top: -7px;
  }
  
  .f-search-ic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25px;
  }
  
  .f-input {
    width: 100%;
    background: transparent;
    border: none;
    padding-top: 5px;
  }
  
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .career-detail-modal {
  }
  
  .career-detail-item-modal {
    margin-top: 30px;
  }
  
  .career-detail-item-modal h1 {
    font-size: 31px;
  }
  
  .form-ctn {
    margin-top: 30px;
  }
  
  .rounded-sm {
    border-radius: 11px !important;
    background-color: white;
  }
  
  .form-ctn-left {
  }
  
  .form-ctn-right {
  }
  
  .form-label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
  
  ::v-deep .v-input input {
    font-family: "Poppins", sans-serif;
  }
  
  .form-label {
    border-radius: 10px;
  }
  
  ::v-deep v-text-field {
    border-radius: 10px;
  }
  
  ::v-deep .row {
    margin: -12px;
  }
  
  ::v-deep .col {
    padding: 5px 20px !important;
  }
  
  ::v-deep .text-form .v-input__slot {
    border-radius: 10px;
    border: 1px solid rgba(211, 214, 225, 1);
    background-color: white;
  }
  
  ::v-deep .form-upload {
    height: 62px !important;
    padding-left: 10px;
    border: 1px solid rgba(211, 214, 225, 1);
    border-radius: 10px;
  }
  
  ::v-deep .form-upload .v-input__slot {
    height: 62px !important;
    background-color: transparent;
    border: none !important;
  }
  
  ::v-deep .form-upload fieldset {
    display: none !important;
    /* Menyembunyikan fieldset */
  }
  
  ::v-deep .form-upload .v-text-field__details {
    display: none;
    border: none !important;
  }
  
  .note-file {
    font-weight: 400;
    font-size: 12px;
    color: #b80f0a;
    margin-bottom: 0px !important;
  }
  
  .career-detail-modal h3 {
    text-align: right;
  }
  </style>
  