<template>
    <div>
        <!-- Tabel -->
        <div class="container-tabel">
            <table class="tabel borderless-table">
                <!-- Render Header -->
                <thead style="border: 2px solid black">
                    <tr>
                        <th class="table-head border-tabel" :style="{ width: '50px' }">No</th>
                        <th v-for="(header, index) in headers" :key="index" class="table-head border-tabel"
                            :style="{ width: header.width ? `${header.width}px` : 'auto' }">
                            {{ header.text }}
                        </th>
                        <th class="table-floting border-tabel" :style="`width: ${widthAction}px !important`">Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(row, rowIndex) in rows" :key="rowIndex"
                        :style="{ backgroundColor: rowIndex % 2 === 1 ? '#f5f6f9' : 'transparent' }">
                        <td class="table-body tbody" :style="{ width: '50px' }">
              {{ getRowNumber(rowIndex) }}
            </td>

                        <td v-for="(header, colIndex) in headers" :key="colIndex" class="table-body tbody"
                            :style="{ width: header.width ? `${header.width}px` : 'auto' }">
                            <template v-if="hasCustomRender(header.field)">
                                <div v-html="getCustomRender(header.field, row)"></div>
                            </template>
                            <template v-else>
                                {{ row[header.field] }}
                            </template>
                        </td>
                        <td :style="`background-color: ${rowIndex % 2 === 1 ? '#f5f6f9' : ''}; width: ${widthAction}px !important`"
                            class="table-floting">
                            <div class="warp-btn-action">
                                <button class="btn-action" @click.prevent="onEdit(row.id)">
                                    UBAH
                                </button>
                                <button class="btn-action" @click.prevent="onView(row.id)">
                                    LIHAT
                                </button>
                                <button class="btn-action" @click.prevent="onDelete(row.id)">
                                    HAPUS
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Pagination -->
        <v-row justify="center">
            <v-col cols="12">
                <v-container class="max-width">
                    <v-pagination v-model="localCurrentPage" class="my-4" :length="totalPages"
                        @input="handlePageChange"></v-pagination>
                </v-container>
            </v-col>
        </v-row>

        <!-- Informasi Total Data -->
        <h5 style="font-weight: 400" class="text-jumlah">
            Menampilkan
            <span style="font-weight: 700">{{
                Math.floor((currentPage - 1) * perPage + rows.length)
                }}</span>
            dari
            <span style="font-weight: 700">{{ totalData }}</span>
        </h5>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true,
        },
        rows: {
            type: Array,
            required: true,
        },
        currentPage: {
            type: Number,
            required: false,
            default: 0,
        },
        totalPages: {
            type: Number,
            required: false,
            default: 0,
        },
        totalData: {
            type: Number,
            required: false,
            default: 0,
        },
        perPage: {
            type: Number,
            required: false,
            default: 0,
        },
        onEdit: {
            type: Function,
            required: false,
        },
        onView: {
            type: Function,
            required: false,
        },
        onDelete: {
            type: Function,
            required: false,
        },
        widthAction: {
            type: Number,
            default: 250
        },
        onPageChange: {
            type: Function,
            required: false,
        },
        customColumns: {
            type: Array,
            default: () => [],
        },

    },
    data() {
        return {
            localCurrentPage: this.currentPage, // Simpan nilai prop ke data property lokal
        };
    },
    watch: {
        currentPage(newPage) {
            this.localCurrentPage = newPage;
        },
    },
    methods: {
        handlePageChange(page) {
            this.onPageChange(page);
        },
        hasCustomRender(field) {
            return this.customColumns.some((col) => col.field === field);
        },
        getCustomRender(field, row) {
            const column = this.customColumns.find((col) => col.field === field);
            return column ? column.render(row) : '';
        },
        getRowNumber(rowIndex) {
      return (this.currentPage - 1) * this.perPage + rowIndex + 1;
    },
    },
};
</script>

<style scoped>
.tbody {
    margin: 0;
    font-weight: 400;
    border-collapse: collapse;
    font-size: 14px;
}

.borderless-table {
    table-layout: fixed;
    width: 100%;
}

.tbody-color-red {
    color: #b80f0a;
}

.tbody-color-gray {
    color: #7b7e8c;
}

.container-tabel {
    background-color: white;
    margin: 20px;
    overflow-x: auto;
}

.tabel {
    position: relative;
    border: none;
    width: 100%;
    border-collapse: collapse;
}

.table-head {
    min-width: 200px;
    background-color: white;
    white-space: nowrap;
    width: auto;
    padding: 10px;
}

.border-tabel {
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid #e5e7e9;
}

.table-body {
    min-width: 200px;
    height: 141px;
    white-space: nowrap;
    width: auto;
    padding: 10px;
    text-align: center;
}

.table-floting {
    white-space: nowrap;
    text-align: center;
    position: sticky;
    right: 0;
    background-color: white;
}

.warp-btn-action {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.btn-action {
    background-color: white;
    width: 79px;
    height: 38px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #575966;
    font-size: 14px;
    font-weight: 500;
    color: #2d46cf;
    border: 1px solid #acafbf;
}

.text-jumlah {
    font-size: 12px;
    font-weight: 400px;
}

.pegenation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.pegenation-text {
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    margin-right: 15px;
}
</style>