<template>
  <div
    class="d-flex flex-column"
    style="padding: 32px 30px 62px 33px; gap: 33px"
  >
    <v-row
      style="
        background-color: #fff;
        padding: 26px 39px;
        color: #36373f;
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        border-radius: 10px;
      "
      no-gutters
    >
      OVERVIEW
    </v-row>
    <div class="d-flex flex-row" style="gap: 35px">
      <div
        class="d-flex flex-column"
        style="min-width: 834px !important; gap: 33px"
      >
        <v-col
          style="
            background-color: #fff;
            border-radius: 10px;
            padding: 37px 39px;
          "
        >
          <v-row class="align-center">
            <v-col class="card-title"> Activity </v-col>
            <v-col class="card-link" style="text-align: flex-end">
              <span @click="toChangePath('/statistic', 'Beranda')"> Selengkapnya </span>
            </v-col>
          </v-row>
          <v-spacer style="height: 40px" />
          <v-row>
            <v-col>
              <v-row class="align-center" style="gap: 6px" no-gutters>
                <span class="activity-title"> Total User </span>
                <div class="info-icon-wrapper">
                  <InfoIcon class="info-icon" />
                  <div class="tooltip">
                    Total user secara keseluruhan aktif dan nonaktif
                  </div>
                </div>
              </v-row>
              <v-row no-gutters class="activity-number">
                {{ this.activity.total_user }}
              </v-row>
            </v-col>
            <v-col style="border-left: #acafbf 1px solid; padding-left: 32px">
              <v-row class="align-center" style="gap: 6px" no-gutters>
                <span class="activity-title"> User Baru </span>
                <div class="info-icon-wrapper">
                  <InfoIcon class="info-icon" />
                  <div class="tooltip">
                    Kenaikan jumlah user dalam kurun waktu 30 hari terakhir
                  </div>
                </div>
              </v-row>
              <v-row no-gutters class="activity-number align-center">
                {{ this.activity.new_user }}
                <div class="activity-percentage" v-if="percentage">
                  <Arrow2 direct="up" fill="#0EA976" />
                  {{ this.activity.percentage }}%
                </div>
                <div class="activity-percentage-negative" v-if="!percentage">
                  <Arrow2 direct="down" fill="#a90e0e" />
                  {{ this.activity.percentage }}%
                </div>
              </v-row>
            </v-col>
            <v-col style="border-left: #acafbf 1px solid; padding-left: 32px">
              <v-row class="align-center" style="gap: 6px" no-gutters>
                <span class="activity-title"> User Aktif </span>
                <div class="info-icon-wrapper">
                  <InfoIcon class="info-icon" />
                  <div class="tooltip">Total user aktif secara keseluruhan</div>
                </div>
              </v-row>
              <v-row no-gutters class="activity-number">
                {{ this.activity.total_user_active }}
              </v-row>
            </v-col>
          </v-row>
          <v-spacer style="height: 53px" />
          <v-row no-gutters justify="space-between" class="align-center">
            <div class="d-flex" style="gap: 12px">
              <v-row no-gutters style="gap: 7px" class="align-center">
                <div
                  style="
                    background-color: #34b3f1;
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                  "
                ></div>
                User Aktif
              </v-row>
              <v-row no-gutters style="gap: 7px" class="align-center">
                <div
                  style="
                    background-color: #41b57a;
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                  "
                ></div>
                User Tidak Aktif
              </v-row>
            </div>
            <div>
              <v-select
                @change="getUserChart"
                v-model="filter.category"
                :items="categories"
                :item-text="'name'"
                :item-value="'value'"
                hide-details="auto"
                class="select-chart"
                solo
              />
            </div>
          </v-row>
          <div>
            <VueApexCharts
              ref="realtimeChart"
              height="330"
              :options="chartOptions"
              :series="series"
            />
          </div>
        </v-col>
        <v-col
          style="
            background-color: #fff;
            border-radius: 10px;
            padding: 37px 44px 30px 39px;
          "
        >
          <v-row class="align-center">
            <v-col class="card-title"> Program Aktif </v-col>
            <v-col class="card-link" style="text-align: flex-end">
              <span @click="toChangePath('/class-category', 'Kelas')"> Selengkapnya </span>
            </v-col>
          </v-row>
          <div style="margin-top: 20px">
            <v-select
              @change="getUserChart"
              v-model="filterProgram.category"
              :items="categoriesProgram"
              :item-text="'name'"
              :item-value="'value'"
              hide-details="auto"
              class="select-chart"
              solo
            />
          </div>
          <div
            v-if="this.filterProgram.category === 'b2c'"
            style="
              display: flex;
              justify-content: space-between;
              gap: 10px 10px;
              flex-wrap: wrap;
            "
          >
            <div class="card-program">
              <div
                style="
                  display: flex;
                  width: 44px;
                  height: 79px;
                  background-color: #f1f6ff;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg
                  width="21"
                  height="24"
                  viewBox="0 0 21 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66843 23.3293C2.09822 23.3293 1.62222 23.1384 1.24043 22.7566C0.858639 22.3748 0.66733 21.8984 0.666504 21.3274V5.49791C0.666504 4.9277 0.857813 4.4517 1.24043 4.06991C1.62305 3.68811 2.09905 3.49681 2.66843 3.49598H4.86249V1.3986C4.86249 1.20853 4.92654 1.04987 5.05463 0.922604C5.18355 0.794514 5.34304 0.730469 5.53311 0.730469C5.72318 0.730469 5.88143 0.794514 6.00787 0.922604C6.13431 1.05069 6.19752 1.20936 6.19752 1.3986V3.49598H15.0643V1.3515C15.0643 1.17548 15.1238 1.02797 15.2428 0.908969C15.3618 0.789969 15.5093 0.730469 15.6853 0.730469C15.8613 0.730469 16.0084 0.789969 16.1266 0.908969C16.2448 1.02797 16.3038 1.17507 16.3038 1.35026V3.49598H18.4979C19.0681 3.49598 19.5441 3.68729 19.9259 4.06991C20.3077 4.45252 20.499 4.92852 20.4998 5.49791V21.3274C20.4998 21.8976 20.3089 22.3736 19.9272 22.7554C19.5454 23.1372 19.0689 23.3285 18.4979 23.3293H2.66843ZM2.66843 22.0897H18.4979C18.688 22.0897 18.8628 22.0104 19.0223 21.8517C19.1817 21.6931 19.2611 21.5183 19.2603 21.3274V10.4562H1.90609V21.3274C1.90609 21.5175 1.98542 21.6922 2.14409 21.8517C2.30275 22.0112 2.47753 22.0906 2.66843 22.0897ZM5.62484 14.0324C5.44882 14.0324 5.30172 13.9729 5.18355 13.8539C5.06455 13.7349 5.00505 13.5878 5.00505 13.4126C5.00505 13.2375 5.06455 13.0904 5.18355 12.9714C5.30255 12.8524 5.44964 12.7929 5.62484 12.7929H15.5415C15.7175 12.7929 15.8646 12.8524 15.9828 12.9714C16.1018 13.0904 16.1613 13.2379 16.1613 13.4139C16.1613 13.5899 16.1018 13.737 15.9828 13.8552C15.8638 13.9734 15.7167 14.0324 15.5415 14.0324H5.62484ZM5.62484 18.9908C5.44882 18.9908 5.30172 18.9313 5.18355 18.8123C5.06455 18.6933 5.00505 18.5462 5.00505 18.371C5.00505 18.1958 5.06455 18.0487 5.18355 17.9297C5.30255 17.8107 5.44964 17.7512 5.62484 17.7512H11.8228C11.9988 17.7512 12.1459 17.8107 12.264 17.9297C12.383 18.0487 12.4425 18.1962 12.4425 18.3722C12.4425 18.5482 12.383 18.6953 12.264 18.8135C12.145 18.9317 11.9979 18.9908 11.8228 18.9908H5.62484Z"
                    fill="url(#paint0_linear_2530_5112)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2530_5112"
                      x1="8.06268"
                      y1="11.1692"
                      x2="21.339"
                      y2="26.3272"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  align-self: center;
                  width: 270px;
                "
              >
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(54, 55, 63);
                  "
                  >Event</span
                >
                <span>{{ this.programActivity.b2c.event }} kelas</span>
              </div>
              <div style="align-self: center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                    fill="#ACAFBF"
                  />
                </svg>
              </div>
            </div>

            <div class="card-program">
              <div
                style="
                  display: flex;
                  width: 44px;
                  height: 79px;
                  background-color: #f1f6ff;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2530_5097)">
                    <path
                      d="M12 0.875C5.57812 0.875 0.375 6.07812 0.375 12.5C0.375 18.9219 5.57812 24.125 12 24.125C18.4219 24.125 23.625 18.9219 23.625 12.5C23.625 6.07812 18.4219 0.875 12 0.875ZM14.2594 16.1609H5.775L9.74531 8.84375H18.2297L14.2594 16.1609Z"
                      fill="url(#paint0_linear_2530_5097)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_2530_5097"
                      x1="9.04531"
                      y1="11.6145"
                      x2="22.3608"
                      y2="28.9372"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                    <clipPath id="clip0_2530_5097">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  align-self: center;
                  width: 270px;
                "
              >
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(54, 55, 63);
                  "
                  >Bootcamp</span
                >
                <span>{{ this.programActivity.b2c.bootcamp }} kelas</span>
              </div>
              <div style="align-self: center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                    fill="#ACAFBF"
                  />
                </svg>
              </div>
            </div>

            <div class="card-program">
              <div
                style="
                  display: flex;
                  width: 44px;
                  height: 79px;
                  background-color: #f1f6ff;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.00333 6.61368C1.89473 8.30463 1.25 10.327 1.25 12.5C1.25 18.4371 6.06294 23.25 12 23.25C17.9371 23.25 22.75 18.4371 22.75 12.5C22.75 8.57887 20.6506 5.14808 17.5146 3.27031C17.4246 3.79547 16.2945 4.28174 15.0415 4.82085C13.8972 5.31322 12.6504 5.84967 12 6.5001C11.7064 6.79373 11.6427 7.31728 11.5838 7.80069C11.4924 8.55205 11.4127 9.20648 10.5 8.7501C10.3356 8.58569 10.2072 8.42127 10.0912 8.27266C9.67791 7.74336 9.42116 7.41452 8.25 8.0001C6.75 8.7501 9.83203 11.5274 10.5 11.7501C10.7826 11.8443 11.0063 11.6505 11.2646 11.4268C11.6167 11.1217 12.033 10.7611 12.75 11.0001C15 11.7501 18.2071 12.8861 18 14.7501C17.6976 17.4717 14.25 21.5001 12 22.2501C11.0608 22.2501 11.3478 21.2034 11.6696 20.0297C11.8882 19.2323 12.1229 18.3763 12 17.7501C11.8223 16.8449 10.9373 16.5574 10.0713 16.2762C9.17671 15.9856 8.30254 15.7016 8.25 14.7501C8.20718 13.9746 8.76361 13.4449 9.38665 12.8518C9.50656 12.7377 9.62895 12.6212 9.75 12.5001C9.82442 12.4257 9.35573 12.1685 8.63476 11.773C6.7467 10.7371 3.1285 8.75207 3.00333 6.61368ZM12 0.75C5.51065 0.75 0.25 6.01065 0.25 12.5C0.25 18.9893 5.51065 24.25 12 24.25C18.4893 24.25 23.75 18.9893 23.75 12.5C23.75 6.01065 18.4893 0.75 12 0.75Z"
                    fill="url(#paint0_linear_2530_4490)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2530_4490"
                      x1="9.01354"
                      y1="11.605"
                      x2="22.4723"
                      y2="29.1139"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  align-self: center;
                  width: 270px;
                "
              >
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(54, 55, 63);
                  "
                  >Pub. Training</span
                >
                <span
                  >{{ this.programActivity.b2c.public_training }} kelas</span
                >
              </div>
              <div style="align-self: center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                    fill="#ACAFBF"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            v-if="this.filterProgram.category === 'b2b'"
            style="display: flex; gap: 20px"
          >
            <div class="card-program">
              <div
                style="
                  display: flex;
                  width: 44px;
                  height: 79px;
                  background-color: #f1f6ff;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0.879883L7.14 11.5599C6.8 12.5999 7.04 13.7899 7.86 14.6199C8.42421 15.1823 9.18836 15.4981 9.985 15.4981C10.7816 15.4981 11.5458 15.1823 12.11 14.6199C12.9 13.8299 13.16 12.6999 12.89 11.7099M12.6 2.84988L13.22 5.17988C14.6418 5.80365 15.8511 6.82814 16.7 8.12813C17.5489 9.42812 18.0006 10.9473 18 12.4999C18 14.7099 17.11 16.7099 15.66 18.1499H15.65C15.5573 18.2424 15.4837 18.3523 15.4336 18.4733C15.3834 18.5942 15.3576 18.7239 15.3576 18.8549C15.3576 18.9859 15.3834 19.1155 15.4336 19.2365C15.4837 19.3575 15.5573 19.4674 15.65 19.5599C16.04 19.9499 16.68 19.9499 17.07 19.5699C18 18.6425 18.7376 17.5405 19.2404 16.3272C19.7432 15.1139 20.0014 13.8132 20 12.4999C20 7.87988 16.86 3.99988 12.6 2.84988ZM7.4 2.85988C3.15 3.99988 0 7.89988 0 12.4999C0 15.2599 1.12 17.7599 2.93 19.5699C3.32 19.9499 3.95 19.9499 4.34 19.5599C4.4327 19.4674 4.50625 19.3575 4.55643 19.2365C4.60661 19.1155 4.63244 18.9859 4.63244 18.8549C4.63244 18.7239 4.60661 18.5942 4.55643 18.4733C4.50625 18.3523 4.4327 18.2424 4.34 18.1499C3.5963 17.4092 3.00662 16.5286 2.605 15.5589C2.20338 14.5892 1.99775 13.5495 2 12.4999C2 9.14988 3.94 6.35988 6.79 5.14988"
                    fill="url(#paint0_linear_2570_21402)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2570_21402"
                      x1="7.45833"
                      y1="9.64413"
                      x2="18.1649"
                      y2="24.326"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  align-self: center;
                  width: 270px;
                "
              >
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(54, 55, 63);
                  "
                  >Acc. Program</span
                >
                <span>{{ this.programActivity.b2b.acceleration }} kelas</span>
              </div>
              <div style="align-self: center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                    fill="#ACAFBF"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            v-if="this.filterProgram.category === 'b2g'"
            style="display: flex; gap: 20px"
          >
            <div class="card-program">
              <div
                style="
                  display: flex;
                  width: 44px;
                  height: 79px;
                  background-color: #f1f6ff;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg
                  width="28"
                  height="29"
                  viewBox="0 0 28 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.9222 8.89984C20.9222 7.9097 20.5289 6.96011 19.8288 6.25997C19.1286 5.55984 18.179 5.1665 17.1889 5.1665H12.2111C11.221 5.1665 10.2714 5.55984 9.57125 6.25997C8.87111 6.96011 8.47778 7.9097 8.47778 8.89984H7.23333C6.24319 8.89984 5.2936 9.29317 4.59347 9.99331C3.89333 10.6934 3.5 11.643 3.5 12.6332V22.5887C3.5 23.5789 3.89333 24.5285 4.59347 25.2286C5.2936 25.9287 6.24319 26.3221 7.23333 26.3221H22.1667C23.1568 26.3221 24.1064 25.9287 24.8065 25.2286C25.5067 24.5285 25.9 23.5789 25.9 22.5887V12.6332C25.9 11.643 25.5067 10.6934 24.8065 9.99331C24.1064 9.29317 23.1568 8.89984 22.1667 8.89984H20.9222ZM17.1889 7.65539H12.2111C11.8811 7.65539 11.5645 7.7865 11.3312 8.01988C11.0978 8.25326 10.9667 8.56979 10.9667 8.89984H18.4333C18.4333 8.56979 18.3022 8.25326 18.0688 8.01988C17.8355 7.7865 17.5189 7.65539 17.1889 7.65539ZM7.23333 11.3887H22.1667C22.4967 11.3887 22.8132 11.5198 23.0466 11.7532C23.28 11.9866 23.4111 12.3031 23.4111 12.6332V22.5887C23.4111 22.9188 23.28 23.2353 23.0466 23.4687C22.8132 23.7021 22.4967 23.8332 22.1667 23.8332H7.23333C6.90329 23.8332 6.58676 23.7021 6.35338 23.4687C6.12 23.2353 5.98889 22.9188 5.98889 22.5887V12.6332C5.98889 12.3031 6.12 11.9866 6.35338 11.7532C6.58676 11.5198 6.90329 11.3887 7.23333 11.3887Z"
                    fill="url(#paint0_linear_2530_5376)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2530_5376"
                      x1="11.8533"
                      y1="14.9386"
                      x2="23.7745"
                      y2="31.3596"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  align-self: center;
                  width: 270px;
                "
              >
                <span
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: rgb(54, 55, 63);
                  "
                  >Prakerja</span
                >
                <span>{{ this.programActivity.b2g.prakerja }} kelas</span>
              </div>
              <div style="align-self: center">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 11.7931C15.8946 11.9807 15.9999 12.235 15.9999 12.5001C15.9999 12.7653 15.8946 13.0196 15.7071 13.2071L10.0501 18.8641C9.95785 18.9597 9.84751 19.0358 9.7255 19.0882C9.6035 19.1407 9.47228 19.1682 9.3395 19.1694C9.20672 19.1705 9.07504 19.1452 8.95215 19.095C8.82925 19.0447 8.7176 18.9704 8.6237 18.8765C8.52981 18.7826 8.45556 18.671 8.40528 18.5481C8.355 18.4252 8.32969 18.2935 8.33085 18.1607C8.332 18.028 8.35959 17.8967 8.412 17.7747C8.46441 17.6527 8.54059 17.5424 8.6361 17.4501L13.5861 12.5001L8.6361 7.55014C8.45394 7.36154 8.35315 7.10894 8.35542 6.84674C8.3577 6.58455 8.46287 6.33373 8.64828 6.14833C8.83369 5.96292 9.0845 5.85775 9.3467 5.85547C9.60889 5.85319 9.8615 5.95399 10.0501 6.13614L15.7071 11.7931Z"
                    fill="#ACAFBF"
                  />
                </svg>
              </div>
            </div>
          </div>
        </v-col>
      </div>
      <div class="d-flex flex-column" style="width: 100%; gap: 33px">
        <v-col
          style="
            padding: 37px 34px 16px 39px;
            border-radius: 10px;
            background-color: #fff;
          "
        >
          <v-row class="align-center">
            <v-col class="card-title"> Revenue (IDR) </v-col>
            <v-col class="card-link" style="text-align: flex-end">
              <span @click="toChangePath('/transaction/b2b/public-training', 'Public Training')"> Selengkapnya </span>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="align-center">
            <v-col>
              <div style="font-size: 12px; font-weight: 400; color: #36373f">
                Total {{ this.currentRevenue.month }} Income
              </div>
              <div style="font-size: 30px; font-weight: 700; color: #0ea976">
                +{{ this.currentRevenue.total }}
              </div>
            </v-col>
            <div class="d-flex flex-column" style="gap: 3px">
              <div
                class="d-flex align-center justify-end"
                style="gap: 7px; text-align: flex-start"
              >
                <div
                  style="
                    width: 20px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #7b7e8c;
                  "
                >
                  B2C
                </div>
                <div
                  style="
                    background-color: #3d55cf;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                  "
                ></div>
              </div>
              <div
                class="d-flex align-center justify-end"
                style="gap: 7px; text-align: flex-start"
              >
                <div
                  style="
                    width: 20px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #7b7e8c;
                  "
                >
                  B2B
                </div>
                <div
                  style="
                    background-color: #537ee7;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                  "
                ></div>
              </div>
              <div
                class="d-flex align-center justify-end"
                style="gap: 7px; text-align: flex-start"
              >
                <div
                  style="
                    width: 20px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #7b7e8c;
                  "
                >
                  B2G
                </div>
                <div
                  style="
                    background-color: #15cdcb;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                  "
                ></div>
              </div>
            </div>
          </v-row>
          <div>
            <VueApexCharts
              ref="revenueChart"
              type="bar"
              :options="chartOptionsRevenue"
              :series="seriesRevenue"
            />
          </div>
        </v-col>
        <div
          class="d-flex flex-column"
          style="
            background-color: #fff;
            border-radius: 10px;
            height: 500px;
            padding: 37px 37px 0px 39px;
            gap: 10px;
            overflow: hidden;
            overflow-y: scroll;
          "
        >
          <v-row class="align-center">
            <v-col class="card-title"> {{ currentSchedule.month }} </v-col>
            <v-col class="card-link" style="text-align: flex-end">
              <span @click="toChangePath('/schedule', 'Schedule')"> Selengkapnya </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(date, idx) in currentSchedule.weekDates"
              :key="idx"
              style="text-align: center"
              @click="scheduleClick(idx)"
              :class="
                activeDateSchedule.index === idx ? 'active-date-schedule' : ''
              "
            >
              <div style="color: #acafbf; font-size: 12px; font-weight: 500">
                {{ weekDays[idx] }}
              </div>
              <div style="font-size: 22px; font-weight: 600; color: #36373f">
                {{ date }}
              </div>
            </v-col>
          </v-row>
          <div style="height: 350px">
          <div
            v-if="scheduleList === null || scheduleList.length === 0"
          >
          </div>
          <v-row style="margin-top: 20px">
          <v-col
            v-for="item in scheduleList"
            :key="item.id"
            cols="12"
          >
            <div
              class="d-flex flex-row align-start"
              style="
                padding: 16px 18px;
                gap: 12px;
                border-radius: 10px;
                border: 0.5px solid #d3d6e1;
              "
            >
              <div
                style="background-color: #f1f6ff; padding: 10px; height: 79px"
                class="d-flex justify-center align-center"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2537_14895)">
                    <path
                      d="M12 0.875C5.57812 0.875 0.375 6.07812 0.375 12.5C0.375 18.9219 5.57812 24.125 12 24.125C18.4219 24.125 23.625 18.9219 23.625 12.5C23.625 6.07812 18.4219 0.875 12 0.875ZM14.2594 16.1609H5.775L9.74531 8.84375H18.2297L14.2594 16.1609Z"
                      fill="url(#paint0_linear_2537_14895)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_2537_14895"
                      x1="9.04531"
                      y1="11.6145"
                      x2="22.3608"
                      y2="28.9372"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2D46CF" />
                      <stop offset="1" stop-color="#85BFF2" />
                    </linearGradient>
                    <clipPath id="clip0_2537_14895">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="d-flex flex-column" style="width: 100%">
                <div
                  class="d-flex flex-column justify-center"
                  style="height: 79px"
                >
                  <span
                    style="font-size: 16px; font-weight: 600; color: #36373f"
                    >{{ item.name }}</span
                  >
                  <span
                    style="color: #7b7e8c; font-size: 14px; font-weight: 500"
                    >{{ item.hours }} WIB</span
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
        </div>
      </div>
    </div>
    <v-row no-gutters style="gap: 35px">
      <v-col class="artikel-box">
        <v-row class="align-center">
          <v-col class="card-title"> Artikel </v-col>
          <v-col class="card-link" style="text-align: flex-end">
            <span @click="toChangePath('/blog', 'Article')"> Selengkapnya </span>
          </v-col>
        </v-row>
        <v-spacer style="height: 22px" />
        <div class="d-flex flex-column" style="gap: 18px">
          <div
            class="artikel-box-ctn"
            v-for="(item, idx) in article.blogs"
            :key="idx"
          >
            <div class="new-artikel" v-if="idx === 0">
              Terbaru
            </div>
            <p class="art-title">
              {{ item.title }}
            </p>
            <p class="art-date">
              {{ item.date_release }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col class="artikel-box">
      <h1>Coming Soon</h1>
        <!-- <v-row class="align-center">
          <v-col class="card-title"> News </v-col>
          <v-col class="card-link" style="text-align: flex-end">
            <span @click="console.log('clicked')"> Selengkapnya </span>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import setHeader from "@/utils/auth.js";
import getAuthentification from "@/utils/badrequest.js";
import VueJwtDecode from "vue-jwt-decode";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import VueApexCharts from "vue-apexcharts";
import Arrow2 from "@/components/icons/Arrow2.vue";
import moment from "moment";

export default {
  components: {
    InfoIcon,
    VueApexCharts,
    Arrow2,
  },
  data() {
    return {
      currentRevenue: {
        month: "",
        total: null,
      },
      showTooltip: false,
      activeDateSchedule: {
        index: 0, date: moment(new Date()).format("YYYY-MM-DD")
      },
      scheduleList: [],
      filterProgram: {
        category: "b2c",
      },
      categoriesProgram: [
        {
          name: "B2C",
          value: "b2c",
        },
        {
          name: "B2B",
          value: "b2b",
        },
        {
          name: "B2G",
          value: "b2g",
        },
      ],
      programActivity: {},
      currentSchedule: {
        month: "",
        weekDates: [],
      },
      activity: {},
      article: {},
      filter: {
        category: "MONTH",
      },
      percentage: true,
      categories: [
        {
          name: "Daily",
          value: "WEEK",
        },
        {
          name: "Monthly",
          value: "MONTH",
        },
        {
          name: "Yearly",
          value: "YEAR",
        },
      ],
      weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      series: [
        {
          name: "User Aktif",
          data: [],
        },
        {
          name: "User Tidak Aktif",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: false,
          style: {
            fontSize: "12px",
          },
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        colors: ["#2461C3", "#41B57A"],
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val) {
              return val.toFixed(0); // Menampilkan angka tanpa desimal
            },
          },
        },
      },
      seriesRevenue: [
        {
          name: "B2B",
          data: [],
        },
        {
          name: "B2C",
          data: [],
        },
        {
          name: "B2G",
          data: [],
        },
      ],
    };
  },
  methods: {
    toChangePath(path, name) {
      sessionStorage.setItem("layout", name)
      this.$router.push(path).catch(() => {})
    },
    updateCurrentRevenue(event, chartContext, config) {
      let b2b = this.seriesRevenue[0].data[config.dataPointIndex];
      let b2c = this.seriesRevenue[1].data[config.dataPointIndex];
      let b2g = this.seriesRevenue[2].data[config.dataPointIndex];
      let total = Number(b2b) + Number(b2c) + Number(b2g);

      if (total.toString().length > 3) {
        total = total / 1000;
        total = total.toFixed(0) + "K";
      }

      this.currentRevenue = {
        month: moment()
          .startOf("quarter")
          .add(config.dataPointIndex, "month")
          .format("MMMM"),
        total: total,
      };
    },
    scheduleClick(idx) {
      this.activeDateSchedule = {
        index: idx,
        date: moment().startOf("isoWeek").add(idx, "days").format("YYYY-MM-DD"),
      };
      this.getSchedule();
    },
    async loginDashboard(email, token, role) {
      // this.spin_load.val = true
      await this.$http
        .post("/v1/login-dashboard", {
          email: email,
          token: token,
          user_type_id: parseInt(role),
        })
        .then((response) => {
          if (response.data.data.user.user_type_id != 1) {
            window.location.href = "/bad-request";
          }
        })
        .catch((err) => console.error(err.response));
    },
    async getUserChart() {
      try {
        const response = await this.$crm_http.get(
          `/v1/user/chart?category=${this.filter.category}`
        );
        const data = response.data.data;
        this.series[0].data = [];
        this.series[1].data = [];
        this.chartOptions.xaxis.categories = [];

        data.forEach((item) => {
          this.series[0].data.push(item.user_active);
          this.series[1].data.push(item.user_Nonactive);
          const formattedCategory = this.formatCategories(
            item.name,
            this.filter.category
          );
          this.chartOptions.xaxis.categories.push(formattedCategory);
        });
        this.updateLabels();
        this.updateSeriesLine();
        if (data.percentage[0] == "-") {
          this.percentage = false;
        } else {
          this.percentage = true;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updatePrice({ series, seriesIndex, dataPointIndex }) {
            let b2b = series[seriesIndex][dataPointIndex];
            let b2c = series[seriesIndex + 1][dataPointIndex];
            let b2g = series[seriesIndex + 2][dataPointIndex];
            let total = b2b + b2c + b2g;

            if (b2b.toString().length > 3) {
              b2b = b2b / 1000;
              b2b = b2b.toFixed(0) + "K";
            }

            if (b2c.toString().length > 3) {
              b2c = b2c / 1000;
              b2c = b2c.toFixed(0) + "K";
            }

            if (b2g.toString().length > 3) {
              b2g = b2g / 1000;
              b2g = b2g.toFixed(0) + "K";
            }

            if (total.toString().length > 3) {
              total = total / 1000;
              total = total.toFixed(0) + "K";
            }

            return `<v-col style="
            border-radius: 10px;
            font-size= 14px;
            font-weight= 400;
            color= #7B7E8C;
            ">
              <div class="d-flex align-center justify-space-between" style="background-color: #F5F6FA;padding: 8px 16px; gap:30px">
                <div>Total</div>
                <div>${total}</div>
              </div>
              <div class="d-flex align-center justify-space-between" style="background-color: #FFFFFF; padding: 8px 16px">
                <div class="d-flex align-center" style="gap: 4px">
                  <div
                    style="
                      background-color: #3d55cf;
                      width: 12px;
                      height: 12px;
                      border-radius: 100%;
                    "
                  >
                  </div>
                  <div>
                    B2C
                  </div>
                </div>
                <div>${b2c}</div>
              </div>
              <div class="d-flex align-center justify-space-between" style="background-color: #FFFFFF;padding: 8px 16px">
                <div class="d-flex align-center" style="gap: 4px">
                  <div
                    style="
                      background-color: #537EE7;
                      width: 12px;
                      height: 12px;
                      border-radius: 100%;
                    "
                  >
                  </div>
                  <div>
                    B2B
                  </div>
                </div>
                <div>${b2b}</div>
              </div>
              <div class="d-flex align-center justify-space-between" style="background-color: #FFFFFF;padding: 8px 16px">
                <div class="d-flex align-center" style="gap: 4px">
                  <div
                    style="
                      background-color: #15CDCB;
                      width: 12px;
                      height: 12px;
                      border-radius: 100%;
                    "
                  >
                  </div>
                  <div>
                    B2G
                  </div>
                </div>
                <div>${b2g}</div>
              </div>
            </v-col>`;
          },
    async getRevenueChart() {
      let quartal = [];
      let start = moment().startOf("quarter");
      quartal.push(start.format("MMM"));
      for (let i = 1; i < 3; i++) {
        quartal.push(start.clone().add(i, "months").format("MMM"));
      }
      try {
        const response = await this.$crm_http.get(
          `/v1/transaction/chart?start=${start.format("M")}&end=${start
            .clone()
            .add(2, "months")
            .format("M")}`
        );
        const data = response.data.data;
        this.seriesRevenue[0].data = [];
        this.seriesRevenue[1].data = [];
        this.seriesRevenue[2].data = [];
        this.chartOptionsRevenue.xaxis.categories = quartal;

        data.forEach((item) => {
          this.seriesRevenue[0].data.push(item.b2b);
          this.seriesRevenue[1].data.push(item.b2c);
          this.seriesRevenue[2].data.push(item.b2g);

        });

        this.updateLabels();
        this.updateSeriesLine();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.currentRevenue.month = moment(new Date()).format("MMMM");
      let total2 = Number(this.seriesRevenue[2].data[0]) / 1000;
      total2 = total2.toFixed(0) + "K";
      this.currentRevenue.total = total2;
    },
    updateLabels() {
      this.$refs.realtimeChart.updateOptions(this.chartOptions);
      this.$refs.revenueChart.updateOptions(this.chartOptionsRevenue);
    },
    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(this.series, false, true);
      this.$refs.revenueChart.updateSeries(this.seriesRevenue, false, true);
    },
    formatCategories(name, category) {
      if (category === "MONTH") {
        return this.formatMonth(name);
      } else if (category === "WEEK") {
        return this.formatWeek(name);
      } else if (category === "YEAR") {
        return name;
      }
      return name;
    },
    formatMonth(number) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[number - 1] || number;
    },
    formatWeek(number) {
      return this.weekDays[number - 1] || number;
    },
    async getUserTotal() {
      await this.$crm_http
        .get("/v1/user/total")
        .then((response) => {
          this.activity = response.data.data;
        })
        .catch((err) => console.error(err.response));
      if (this.activity.percentage < 0) {
        this.percentage = false;
      } else {
        this.percentage = true;
      }
    },
    async getProgramActivity() {
      await this.$crm_http
        .get("/v1/class/business/category")
        .then((response) => {
          this.programActivity = response.data.data;
        })
        .catch((err) => console.error(err.response));
    },
    async getSchedule() {
      await this.$crm_http
        .get(`/v1/schedule/dashboard/${this.activeDateSchedule.date}`)
        .then((response) => {
          this.scheduleList = response.data.data.schedules
        })
        .catch((err) => console.error(err.response));
    },
    async getArticle() {
      await this.$crm_http
        .get("/v1/blog?page=1&per_page=2")
        .then((response) => {
          this.article = response.data.data;
          this.article.blogs.forEach((item) => {
            item.date_release =
              moment(item.date_release).format("D MMMM YYYY - HH.mm") + " WIB";
          });
        })
        .catch((err) => console.error(err.response));
    },
    getWeekDates() {
      moment.locale("id");
      const startOfWeek = moment().startOf("isoWeek");
      this.currentSchedule.month = startOfWeek.format("MMMM");
      for (let i = 0; i < 7; i++) {
        this.currentSchedule.weekDates.push(
          startOfWeek.clone().add(i, "days").format("D")
        );
      }
    },
  },
  computed: {
    chartOptionsRevenue: function () {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            click: (event, chartContext, config) => {
              this.updateCurrentRevenue(event, chartContext, config);
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: ["#537EE7", "#3D55CF", "#15CDCB"],
        states: {
          normal: {
            filter: {
              type: "darken",
              value: 0.2,
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 1,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: true,
          intersect: false,
          inverseOrder: true,
          custom: (data) => this.updatePrice(data),
          onDatasetHover: {
            highlightDataSeries: true,
          },
        },
      };
    },
  },

  mounted() {
    this.getUserChart();
    this.getUserTotal();
    this.getArticle();
    this.getWeekDates();
    this.getRevenueChart();
    this.getProgramActivity();
    this.getSchedule();
  },
  beforeMount() {
    if (this.$route.params.token) {
      localStorage.setItem("user", this.$route.params.token);
      // setHeader(this.$route.params.token);
      this.$forceUpdate();

      let decoded = VueJwtDecode.decode(this.$route.params.token);
      this.$decode_token = decoded;
      // this.loginDashboard(this.$route.params.email, this.$route.params.token, this.$route.params.role)
    } else {
      getAuthentification();
    }
    var data = JSON.parse(localStorage.getItem("account"));
    if (data != null) {
      this.name = data["full_name"];
    } else {
      this.name = this.$decode_token["name"];
    }
  },
};
</script>
<style scoped>

.artikel-box {
  border-radius: 10px;
          background-color: #fff;
          padding: 18px 30px !important;
}
.artikel-box-ctn {
  border: 0.5px solid #d3d6e1;
              border-radius: 10px;
              padding: 16px 18px;
              background-color: #fff;
}
.new-artikel {
  display: flex;
                padding: 4px 8px;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                background-color: #e9f1fb;
                color: #2671d9;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
                width: 76px;
                margin-bottom: 12px;
}
.art-title {
  display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                flex: 1 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #36373f;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 6px;
}
.art-date {
  color: #7b7e8c;
                font-size: 14px;
                font-weight: 500;
                margin: 0px;
}
.card-title {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  color: #36373f;
}

.card-link {
  color: #2d46cf;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: flex-end;
  text-decoration-line: underline;
}

.card-link span {
  cursor: pointer;
}

.activity-title {
  font-size: 16px;
  font-weight: 500;
  color: #575966;
}

.activity-number {
  font-size: 30px;
  font-weight: 600;
  color: #36373f;
  gap: 10px;
}

::v-deep .select-chart {
  width: 125px;
  border-radius: 10px;
  color: #36373f;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #d9d9d9;
}

::v-deep .v-input__slot {
  box-shadow: none !important;
}

.activity-percentage {
  border-radius: 10px;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  display: flex;
  background-color: #e2fcf3;
  color: #0ea976;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
}

.activity-percentage-negative {
  border-radius: 10px;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  display: flex;
  background-color: #fce2e2;
  color: #a90e0e;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
}

.active-date-schedule {
  background-color: #2d46cf;
  padding: 6px 4px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.active-date-schedule div {
  color: #fff !important;
}

.card-program {
  display: flex;
  width: 363px;
  height: 113px;
  margin-top: 20px;
  padding: 15px;
  /* padding-block: 5px; */
  border: 0.5px solid #d3d6e1;
  border-radius: 10px;
}

.info-icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.info-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.align-center {
  display: flex !important;
  justify-content: space-between !important;
}

.info-icon {
  cursor: pointer;
}
</style>
