<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">{{ name }}</h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Nama</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.name" :rules="[!!form.name || 'Wajib diisi!']"
            class="form-input" placeholder="Nama Program" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Tipe Program</p>
          <v-select :disabled="isWatch" class="form-input" :items="program_type" v-model="form.program_type" solo
            :item-text="'name'" :item-value="'value'" :item-disabled="disabled"></v-select>
        </div>
        <div class="content-form">
          <p>Nama Projek</p>
          <v-text-field :disabled="true" outlined v-model="form.project_name"
            :rules="[!!form.project_name || 'Wajib diisi!']" class="form-input" placeholder="Nama Projek" dense
            clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Gambar Desktop</p>
          <v-file-input v-model="img_web" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload"
            prepend-icon="" outlined>
            <template v-slot:prepend-inner>
              <ImageModal :src="form.img_urls.desktop" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Ukuran file maks 1 MB
          </p>
        </div>
        <div class="content-form">
          <p>URL Gambar (Mobile)</p>

          <v-file-input v-model="img_web2" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload"
            prepend-icon="" outlined>
            <template v-slot:prepend-inner>
              <ImageModal :src="form.img_urls.mobile" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Ukuran file maks 1 MB
          </p>
        </div>
        <div class="content-form">
          <p>Ringkasan</p>
          <v-textarea :disabled="isWatch" outlined v-model="form.summary" :rules="[!!form.summary || 'Wajib diisi!']"
            class="form-input" placeholder="Ringkasan Kegiatan" dense clearable :rows="2"></v-textarea>
        </div>
        <div class="content-form">
          <p>Kuota</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.quota" :rules="[!!form.quota || 'Wajib diisi!']"
            type="number" class="form-input" placeholder="10" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>SKS</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.sks" :rules="[!!form.sks || 'Wajib diisi!']"
            type="number" class="form-input" placeholder="20" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Deskripsi</p>
          <v-textarea :disabled="isWatch" outlined v-model="form.description"
            :rules="[!!form.description || 'Wajib diisi!']" class="form-input" placeholder="Deskripsi Kegiatan" dense
            clearable :rows="5"></v-textarea>
        </div>
        <div class="content-form">
          <p>Tipe Aktivitas</p>
          <v-select :disabled="isWatch" class="form-input" :items="activity_type" v-model="form.activity_type" solo
            :item-text="'name'" :item-value="'value'" :item-disabled="disabled"></v-select>
        </div>
        <div class="content-form">
          <p>Cakupan Proyek</p>
          <div class="location-row" v-for="(data, idx) in form.project_scopes" :key="idx">
            <v-text-field :disabled="isWatch" outlined v-model="form.project_scopes[idx]" class="form-input no-space"
              dense placeholder="Cakupan Proyek"></v-text-field>
          </div>
          <div class="criteria-btn">
            <Button name="-" width="40px" height="35px" title="Remove Location"
              @click="() => form.project_scopes.splice(form.project_scopes.length - 1, 1)" outline
              v-if="form.project_scopes.length > 1 && !isWatch" />
            <Button name="+" width="40px" height="35px" title="Add Location" v-if="!isWatch"
              @click="() => form.project_scopes.push('')" />
          </div>
        </div>
        <div class="content-form">
          <p>Kriteria</p>
          <vue-editor :disabled="isWatch" v-model="form.requirements" :editorToolbar="$helpers.customToolBar()"
            class="form-input"></vue-editor>
        </div>
        <div class="content-form" style="margin-top: 20px;">
          <p>Link Registrasi</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.registration_link"
            :rules="[!!form.registration_link || 'Wajib diisi!']" class="form-input" placeholder="Link registrasi" dense
            clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Tanggal Berlangsung</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="5" class="pt-2"> Mulai </v-col>
                <v-col cols="6">
                  <v-text-field :disabled="isWatch" outlined type="date" :rules="[!!form.start_date || 'Wajib diisi!']"
                    v-model="form.start_date" class="form-input" placeholder="00:00" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="6" class="pt-2"> Selesai </v-col>
                <v-col cols="6">
                  <v-text-field :disabled="isWatch" outlined type="date" :rules="[!!form.end_date || 'Wajib diisi!']"
                    v-model="form.end_date" class="form-input" placeholder="00:00" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form" style="margin-top: 15px;" v-if="form.activity_type != 'REMOTE'">
          <p>Lokasi</p>
          <div class="location-row" v-for="(data, idx) in form.locations" :key="idx">
            <span>Lokasi {{ idx + 1 }}</span>
            <v-text-field disabled outlined v-model="form.locations[idx].name" class="form-input no-space" dense
              placeholder="Nama Lokasi"></v-text-field>
            <v-text-field disabled outlined v-model="form.locations[idx].address" class="form-input no-space"
              placeholder="Alamat Lokasi" dense></v-text-field>
            <v-text-field disabled outlined v-model="form.locations[idx].url" class="form-input no-space"
              placeholder="URL Google Maps" dense></v-text-field>
          </div>
          <!-- <div class="criteria-btn">
            <Button name="-" width="40px" height="35px" title="Remove Location"
              @click="() => form.locations.splice(form.locations.length - 1, 1)" outline
              v-if="form.locations.length > 1 && !isWatch" />
            <Button name="+" width="40px" height="35px" title="Add Location" v-if="!isWatch"
              @click="() => form.locations.push({ ...location_obj })" />
          </div> -->
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="PUBLISH" width="260px" @click="onSumbmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import Loading from '@/components/ilustration/Loading.vue';
import Button from '@/components/Button.vue';
import Popup from "@/components/others/Popup.vue";
import ImageModal from "@/components/ImageModal.vue";

export default {
  name: 'FormCareer',
  data() {
    return {
      id: null,
      modal: false,
      name: '',
      message: "",
      isPopup: false,
      isLoading: false,
      img_web: null,
      img_web2: null,
      isWatch: false,
      isEdit: false,
      program_type: [
        {
          name: "Magang Bersertifikat",
          value: "MB",
        },
        {
          name: "Studi Independent Bersertifikat",
          value: "SIB",
          disabled: true,
        }
      ],
      activity_type: [
        {
          name: "Hybrid",
          value: "HYBRID",
        },
        {
          name: "On Site",
          value: "ONSITE",
        },
        {
          name: "Remote",
          value: "REMOTE",
        },
      ],
      contract_type: [
        {
          name: 'Full-time',
          value: 'Full-time',
        },
        {
          name: 'Part-time',
          value: 'Part-time',
        },
        {
          name: 'Internship',
          value: 'Internship',
        },
      ],
      criteria_obj: {
        name: '',
        value: '',
      },
      location_obj: {
        name: '',
        address: '',
        url: '',
      },
      form: {
        name: '',
        program_type: 'MB',
        project_name: 'Digital Acceleration Project',
        img_urls: {
          mobile: '',
          desktop: '',
        },
        summary: '',
        quota: 0,
        sks: 20,
        description: '',
        activity_type: 'HYBRID',
        project_scopes: [''],
        requirements: "",
        registration_link: '',
        start_date: '',
        end_date: '',
        locations: [
          {
            name: 'Gedung Ariobimo Sentral',
            address: 'Jl. H. R. Rasuna Said No.Kav. X-2 No. 5, RT.9/RW.4, Kuningan Tim., Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12950',
            url: 'https://www.google.com/maps/place/Ariobimo+Sentral,+Jl.+H.+R.+Rasuna+Said+No.Kav.+X-2+No.+5,+RT.9%2FRW.4,+Kuningan+Tim.,+Kecamatan+Setiabudi,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12950/@-6.2272516,106.8308258,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f3ee271a2a91:0x1a27577809196067!8m2!3d-6.2272569!4d106.8334007!16s%2Fg%2F11cjj3h6rn?entry=ttu',
          }
        ],
      },
    };
  },
  components: {
    VueEditor,
    Button,
    Loading,
    Popup,
    ImageModal
  },
  computed: {
    validData() {
      const regexURL = /^(ftp|http|https):\/\/[^ "]+$/;
      if (this.form.activity_type != 'REMOTE') {
        for (let i = 0; i < this.form.locations.length; i++) {
          if (!regexURL.test(this.form.locations[i].url)) return false;
        }
      }
      return (
        this.form.name &&
        this.form.program_type &&
        this.form.project_name &&
        this.form.summary &&
        this.form.quota &&
        this.form.start_date &&
        this.form.end_date &&
        this.form.sks &&
        this.form.description &&
        this.form.project_scopes[0] &&
        this.form.requirements[0] &&
        this.form.registration_link &&
        this.form.activity_type
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf('watch') >= 0;
      this.isEdit = this.$route.path.lastIndexOf('edit') >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataKampusMerdeka();
    },
    tidyUpDataFromGet(data) {
      const start_date = String(data.start_date).split('T')[0];
      const end_date = String(data.end_date).split('T')[0];

      return {
        ...data,
        start_date,
        end_date,
      };
    },
    async getDataKampusMerdeka() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get('/v1/kampus-merdeka/' + this.id);
        const { kampus_merdeka } = resp.data.data;
        this.form = this.tidyUpDataFromGet(kampus_merdeka);
        this.form.locations = [{
          name: 'Gedung Ariobimo Sentral',
          address: 'Jl. H. R. Rasuna Said No.Kav. X-2 No. 5, RT.9/RW.4, Kuningan Tim., Kecamatan Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12950',
          url: 'https://www.google.com/maps/place/Ariobimo+Sentral,+Jl.+H.+R.+Rasuna+Said+No.Kav.+X-2+No.+5,+RT.9%2FRW.4,+Kuningan+Tim.,+Kecamatan+Setiabudi,+Kota+Jakarta+Selatan,+Daerah+Khusus+Ibukota+Jakarta+12950/@-6.2272516,106.8308258,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f3ee271a2a91:0x1a27577809196067!8m2!3d-6.2272569!4d106.8334007!16s%2Fg%2F11cjj3h6rn?entry=ttu',
        }]
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;

      const payload = {
        ...this.form,
        quota: parseInt(this.form.quota),
        sks: parseInt(this.form.sks),
      };

      try {
        if (this.img_web) {
          const imageForm = new FormData();
          imageForm.append("attachment", this.img_web);
          const webRes = await this.$crm_http.post(
            "/v1/upload-file",
            imageForm
          );
          this.form.img_urls.desktop =
            webRes.data.data.upload_image_response.url_w_query;
          imageForm.delete("attachment");
        }
        if (this.img_web2) {
          const imageForm = new FormData();
          imageForm.append("attachment", this.img_web2);
          const webRes = await this.$crm_http.post(
            "/v1/upload-file",
            imageForm
          );
          this.form.img_urls.mobile =
            webRes.data.data.upload_image_response.url_w_query;
          imageForm.delete("attachment");
        }

        if (this.isEdit) {
          await this.$crm_http.put('/v1/kampus-merdeka/' + this.form.id, payload);
        } else {
          await this.$crm_http.post('/v1/kampus-merdeka', payload);
        }
        setTimeout(() => {
          this.$router.push('/kampus-merdeka');
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}

.form-ctn {
  padding: 0 40px;
}

.cart-form {
  background-color: white;

  position: relative;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.no-space {
  margin-bottom: -20px;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}

.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}



.criteria-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
}

.location-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
}

.criteria-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: flex-end;
}

.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}

::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
