<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div style="width: 90%; margin: 30px 0;">
      <div class="menus-top">
        <span class="blue-text" @click="$router.go(-1)">Event</span>
        <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
        <span style="color: #7b7e8c">{{ name }}</span>
      </div>
      <div class="card-form-head">
        <h1>
          Form {{ name }}
        </h1>
        <div class="card-form-head-bdy">
          <p>
            Status {{ form.is_active ? "aktif" : " tidak aktif" }}
          </p>
          <label class="switch">
            <input v-model="form.is_active" type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2">
          <h1>
            Informasi Umum Program
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Nama Event</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.name" class="form-input"
              placeholder="Masukan Nama Event" dense clearable :rules="[!!form.name || 'Wajib diisi!']"></v-text-field>
          </div>
          <div class="content-form">
            <p>Slug Name</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.slug_name" class="form-input"
              placeholder="Masukan Nama Event" dense clearable
              :rules="[!!form.slug_name || 'Wajib diisi!']"></v-text-field>
          </div>
          <div class="content-form">
            <p>Batch Series</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.batch" class="form-input" type="number"
              placeholder="Batch Series" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Level Pelatihan</p>
            <v-select :disabled="isWatch" class="form-input" :items="levelList" v-model="form.level" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form">
            <p>DiLengkapi LMS</p>
            <v-select :disabled="isWatch" class="form-input" :items="lmsConfirmList" v-model="form.is_lms_compatible"
              solo :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form">
            <p>Metode Pelaksanaan</p>
            <v-select :disabled="isWatch" class="form-input" :items="methods" v-model="form.method" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form" v-if="form.method === 'Online'">
            <p>Link Pelaksanaan Event</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.learning_url" class="form-input"
              placeholder="Gmeet, zoom, or other" dense clearable></v-text-field>
          </div>
          <div v-else>
            <div class="content-form">
              <p>Nama Lokasi Pelaksanaan</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.location_name" class="form-input"
                placeholder="Masukkan Nama Lokasi Pelaksanaan" dense clearable></v-text-field>
            </div>
            <div class="content-form">
              <p>Alamat Lokasi Pelaksanaan</p>
              <v-textarea placeholder="Masukkan Alamat Lokasi Pelaksanaan" :disabled="isWatch" outlined
                v-model="form.location_address" rows="3" clearable class="form-input"></v-textarea>
            </div>
            <div class="content-form">
              <p>URL Link Maps Lokasi Pelaksanaan</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.location_map" class="form-input"
                placeholder="Masukkan URL Link Maps" dense clearable></v-text-field>
            </div>
          </div>
          <div class="content-form">
            <p>Narasumber</p>
            <div v-for="(item, idx) in form.instructors" :key="idx" class="session-nb-3">
              <div @click="addNewInsturctor" style="cursor: pointer" v-if="form.instructors.length - 1 === idx">
                <add-icon />
              </div>
              <v-autocomplete :label="`Pilih narasumber ${idx + 1}`" outlined v-model="form.instructors[idx]"
                :items="instructors" class="form-input mt-2 no-space" dense :item-text="'name'"
                :item-value="'id'"></v-autocomplete>
              <div @click="form.instructors.splice(idx, 1)" v-if="idx != 0" style="cursor: pointer">
                <trash :width="38" :height="38" />
              </div>
            </div>
          </div>
          <v-row class="mt-5">
            <v-col cols="6">
              <div class="content-form ">
                <p>Kuota Peserta</p>
                <v-select :disabled="isWatch" class="form-input" :items="typeLimitEvent" v-model="limitOfEvent" solo
                  :item-text="'name'" :item-value="'value'">
                </v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="content-form">
                <p>Batas Kuota</p>
                <v-text-field :disabled="!limitOfEvent" outlined v-model="form.participant_quota" class="form-input"
                  type="number" min="1" dense clearable></v-text-field>
              </div>
            </v-col>
          </v-row>
          <div class="content-form mt-5">
            <p>Deskripsi Singkat Thumbnail</p>
            <v-textarea placeholder="Tulis caption ...." :disabled="isWatch" outlined rows="3" v-model="form.caption"
              clearable class="form-input"></v-textarea>
          </div>
          <div class="content-form ">
            <p>Deskripsi Event</p>
            <vue-editor v-model="form.description" :editorToolbar="$helpers.customToolBar()" class="form-input"
              placeholder="Tulis Deskripsi ...." v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="form.description"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Berkas Gambar (Website Desktop)
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Banner Thumbnail</p>
            <span class="alert-info">Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.desktop.thumbnail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.thumbnail == null">
                <ImageModal :src="form.img_urls.desktop.thumbnail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Detail Produk</p>
            <span class="alert-info">Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.desktop.product_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.product_detail == null">
                <ImageModal :src="form.img_urls.desktop.product_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Rincian Pembelian</p>
            <span class="alert-info">Rekomendasi dimensi file 200 x 200 px, kapasitas maksimal 1
              MB</span>
            <v-file-input v-model="imgModel.desktop.purchase_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.purchase_detail == null">
                <ImageModal :src="form.img_urls.desktop.purchase_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Berkas Gambar (Website Mobile)
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Banner Thumbnail</p>
            <span class="alert-info">Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.mobile.thumbnail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.thumbnail == null">
                <ImageModal :src="form.img_urls.mobile.thumbnail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Detail Produk</p>
            <span class="alert-info">Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.mobile.product_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.product_detail == null">
                <ImageModal :src="form.img_urls.mobile.product_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Rincian Pembelian</p>
            <span class="alert-info">Rekomendasi dimensi file 200 x 200 px, kapasitas maksimal 1
              MB</span>
            <v-file-input v-model="imgModel.mobile.purchase_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.purchase_detail == null">
                <ImageModal :src="form.img_urls.mobile.purchase_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Jadwal Pelaksanaan
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form" v-if="form.is_lms_compatible">
            <p>Hari pertemuan</p>
            <v-text-field :disabled="isWatch" outlined v-model="formClass.class_detail.days"
              :rules="[!!formClass.class_detail.days || 'Wajib diisi!']" class="form-input"
              placeholder="Senin, Rabu, & Jumat" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Sesi Pelaksanaan</p>
            <v-select :disabled="isWatch" class="form-input" :items="listSessionSchedule" v-model="sessionSchedule" solo
              @change="checkScheduleType" :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div v-for="(item, idx) in form.session_schedules" :key="idx">
            <div v-if="sessionSchedule === 2" class="session-numb">
              <p style="font-weight: bold">Sesi {{ idx + 1 }}</p>
              <div @click="form.session_schedules.splice(idx, 1)" style="cursor: pointer" v-if="idx != 0">
                <strip />
              </div>
            </div>
            <div class="content-form">
              <p>Hari & Tanggal Pelaksanaan</p>
              <div class="container-date pt-2" v-if="sessionSchedule === 1">
                <div class="container-date-left">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Mulai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="date"
                        :rules="[!!item.date_start || 'Wajib diisi!']" v-model="item.date_start" class="form-input"
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="container-date-rigth">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Selesai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="date"
                        :rules="[!!item.date_end || 'Wajib diisi!']" v-model="item.date_end" class="form-input"
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-text-field v-else :disabled="isWatch" outlined type="date"
                :rules="[!!item.date_start || 'Wajib diisi!']" v-model="item.date_start" class="form-input"
                dense></v-text-field>
            </div>
            <div class="content-form">
              <p>Jam Pelaksanaan</p>
              <div class="container-date pt-2">
                <div class="container-date-left">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Mulai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="time"
                        :rules="[!!item.start_time || 'Wajib diisi!']" v-model="item.start_time" class="form-input"
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="container-date-rigth">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Selesai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="time"
                        :rules="[!!item.end_time || 'Wajib diisi!']" v-model="item.end_time" class="form-input"
                        dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
          <div class="add-button" @click="addSessionSchedlue" v-if="sessionSchedule === 2 && !isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Sesi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="cart-form" v-if="form.is_lms_compatible">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Support class
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Prefix Code</p>
            <v-text-field :disabled="isWatch" outlined v-model="formClass.class.prefix_code"
              :rules="[!!formClass.class.prefix_code || 'Wajib diisi!']" class="form-input" placeholder="Prefix Code"
              dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Metode Pembelajaran</p>
            <vue-editor v-model="formClass.class_detail.learning_objective" :editorToolbar="$helpers.customToolBar()"
              class="form-input" v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="formClass.class_detail.learning_objective"></div>
            </div>
          </div>
          <div class="content-form mt-6">
            <p>Metode Penyampaian</p>
            <vue-editor v-model="formClass.class_detail.learning_method" :editorToolbar="$helpers.customToolBar()"
              class="form-input" v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="formClass.class_detail.learning_method"></div>
            </div>
          </div>
          <div class="content-form mt-5">
            <p>Link Materi (Global)</p>
            <v-text-field :disabled="isWatch" outlined v-model="formClass.class.material" class="form-input"
              placeholder="Link materi" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Link Group Chat</p>
            <v-text-field :disabled="isWatch" outlined v-model="formClass.class.group_chat_link" class="form-input"
              placeholder="Link Group Chat" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Link konsultasi</p>
            <v-text-field :disabled="isWatch" outlined v-model="formClass.class.consultancy_link" class="form-input"
              placeholder="Link konsultasi" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Link rubik penilaian</p>
            <v-text-field :disabled="isWatch" outlined v-model="classLms.rubrik_url" class="form-input"
              placeholder="Link Rubik Penilaian" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Total Quiz</p>
            <v-text-field :disabled="isWatch" outlined v-model="classLms.total_quiz"
              :rules="[!!classLms.total_quiz || 'Wajib diisi!']" class="form-input" :min="1" type="number" @input="() => {
                if (classLms.total_quiz < 0) {
                  classLms.total_quiz = 1;
                }
              }
              " dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Jadwal Konsultasi</p>
            <v-row no-gutters class="pt-2">
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Mulai </v-col>
                  <v-col cols="8">
                    <v-text-field :disabled="isWatch" outlined type="datetime-local"
                      v-model="formClass.class.start_consultation_date" class="form-input" dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Selesai </v-col>
                  <v-col cols="8">
                    <v-text-field :disabled="isWatch" outlined type="datetime-local"
                      v-model="formClass.class.end_consultation_date" class="form-input" dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div class="content-form mb-6">
            <p>Instruksi Final Projek</p>
            <vue-editor v-model="classLms.instruction_final_project" :editorToolbar="$helpers.customToolBar()"
              class="form-input" v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="classLms.instruction_final_project"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-form" v-if="form.is_lms_compatible">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Syarat dan Target Peserta (Informasi untuk LMS)
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Target Audience</p>
            <div style="margin: 10px 0">
              <v-text-field :disabled="isWatch" v-for="(data, idx) in formClass.class_detail.target_audience" :key="idx"
                outlined v-model="formClass.class_detail.target_audience[idx].level" class="form-input no-space"
                dense></v-text-field>
              <div style="display: flex; gap: 20px">
                <Button name="+" width="40px" height="35px" title="Add Tag" v-if="!isWatch"
                  @click="() => formClass.class_detail.target_audience.push({ level: '' })" />
                <Button name="-" width="40px" height="35px" title="Remove Tag" @click="() =>
                  formClass.class_detail.target_audience.splice(
                    formClass.class_detail.target_audience.length - 1,
                    1
                  )
                " outline v-if="formClass.class_detail.target_audience.length && !isWatch" />
              </div>
            </div>
          </div>
          <div class="content-form mt-5">
            <p>Syarat & Ketentuan Mengikuti Kelas</p>
            <div class="list-event" v-for="(item2, idx) in formClass.class_detail.requirements" :key="idx">
              <div class="icon-plus-bc" @click="formClass.class_detail.requirements.splice(idx, 1)">
                <trash-icon :width="25" :height="25" />
              </div>
              <b>Ke {{ idx + 1 }}</b>
              <v-text-field :disabled="isWatch" outlined placeholder="Syarat Ketentuan" v-model="item2.name"
                class="form-input no-space" dense></v-text-field>
            </div>
            <div class="add-button" @click="addListRequirements" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Requirements</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Materi Silabus
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Informasi Materi Silabus?</p>
            <div class="spacing-switch">
              <v-switch v-model="form.isSyllabuses" color="primary" hide-details style="margin-top: -5px"></v-switch>
            </div>
          </div>
          <div class="content-form mt-5" v-if="form.isSyllabuses || form.is_lms_compatible">
            <p>Daftar Materi Event</p>
            <div class="list-event" v-for="(item, idx) in form.syllabuses" :key="idx">
              <div class="icon-plus" @click="addSubSession(idx)">
                <add-icon />
              </div>
              <b>Sesi {{ idx + 1 }}</b>
              <div v-for="(item2, idx2) in item" :key="idx2" class="session-nb-3">
                <v-text-field :disabled="isWatch" outlined v-model="form.syllabuses[idx][idx2]"
                  class="form-input mt-2 no-space" dense clearable></v-text-field>
                <div @click="onRemoveEvent(idx, idx2)" style="cursor: pointer">
                  <trash :width="38" :height="38" />
                </div>
              </div>
            </div>
            <div class="add-button" @click="addSubSession(null)" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Sesi</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="content-form" v-if="form.is_lms_compatible">
            <p>Link Materi PerSesi</p>
            <div class="list-event" v-for="(item2, idx) in classLms.materials_link" :key="idx">
              <div class="icon-plus-bc" @click="classLms.materials_link.splice(idx, 1)" v-if="idx > 0">
                <trash-icon :width="25" :height="25" />
              </div>
              <b>Ke {{ idx + 1 }}</b>
              <v-text-field :disabled="isWatch" outlined v-model="item2.iteration" class="form-input mt-2 no-space"
                placeholder="Nomor / Code" dense></v-text-field>
              <v-text-field :disabled="isWatch" outlined placeholder="Deskripsi" v-model="item2.description"
                class="form-input no-space" dense></v-text-field>
            </div>
            <div class="add-button" @click="addListMateri" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Link Materi</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="content-form">
            <p>Link Record</p>
            <div class="list-event" v-for="(item2, idx) in classLms.record_list" :key="idx">
              <div class="icon-plus-bc" @click="classLms.record_list.splice(idx, 1)" v-if="idx > 0">
                <trash-icon :width="25" :height="25" />
              </div>
              <b>Ke </b>
              <v-text-field :disabled="isWatch" outlined v-model="item2.iteration" class="form-input mt-2 no-space"
                placeholder="Sesi ke" dense></v-text-field>
              <v-text-field :disabled="isWatch" outlined placeholder="Link record class" v-model="item2.description"
                class="form-input no-space" dense></v-text-field>
            </div>
            <div class="add-button" @click="addRecordList" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Link Record</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="content-form" v-if="form.is_lms_compatible">
            <p>Instruksi TPM</p>
            <div class="list-event mt-5" v-for="(item, idx) in classLms.instruction_tpm" :key="idx">
              <div class="icon-plus-bc" @click="classLms.instruction_tpm.splice(idx, 1)" v-if="idx > 0">
                <trash-icon :width="25" :height="25" />
              </div>
              <v-text-field :disabled="isWatch" outlined label="Sesi Ke"
                v-model="classLms.instruction_tpm[idx].iteration" class="form-input no-space" dense></v-text-field>
              <vue-editor v-model="classLms.instruction_tpm[idx].description" :editorToolbar="$helpers.customToolBar()"
                class="form-input" v-if="!isWatch"></vue-editor>
            </div>
            <div class="add-button" @click="addInstructionTpm" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah instruksi</div>
              <div class="line"></div>
            </div>
          </div>
        </div>

      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Harga Event
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Tipe Tiket</p>
            <v-select :disabled="isWatch" class="form-input" :items="typeTiket" v-model="form.is_event_paid" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div v-if="form.is_event_paid">
            <div class="content-form">
              <p>Harga Tiket</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.price" class="form-input" placeholder="Harga"
                type="number" :min="1" @input="() => {
                  if (form.price < 0) {
                    form.price = 0;
                  }
                }
                " dense clearable></v-text-field>
            </div>

            <div class="content-form">
              <p>Potongan harga</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.discount_price" class="form-input" :min="0"
                prefix="IDR" type="number" @input="() => {
                  if (form.discount_price < 0) {
                    form.discount_price = 0;
                  }
                }
                " dense clearable></v-text-field>
            </div>
            <div class="content-form">
              <p>Durasi Earlybird</p>
              <v-row no-gutters class="pt-2">
                <v-col cols="6">
                  <v-row no-gutters>
                    <v-col cols="4" class="pt-2"> Mulai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="datetime-local"
                        v-model="form.start_discount_date" class="form-input" dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5 ml-5">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Selesai </v-col>
                    <v-col cols="9">
                      <v-text-field :disabled="isWatch" outlined type="datetime-local" v-model="form.end_discount_date"
                        class="form-input" dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div class="content-form mt-5">
              <p>Label Diskon</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.label_discount" class="form-input"
                placeholder="Label Diskon" dense clearable></v-text-field>
            </div>
          </div>
          <div class="content-form">
            <p>Batas Pembelian Tiket</p>
            <v-text-field :disabled="isWatch" outlined type="datetime-local"
              :rules="[!!form.registration_close_date || 'Wajib diisi!']" v-model="form.registration_close_date"
              class="form-input" dense></v-text-field>
          </div>

        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Soal Kurasi
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Dilengkapi Soal Kurasi?</p>
            <v-select :disabled="isWatch" class="form-input" :items="isCurationList" v-model="form.is_curation" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form" v-if="form.is_curation">
            <p>Pertanyaan</p>
            <div class="question" v-for="(n, idx) in form.question" :key="idx">
  <div class="question-remove" @click="removeQuestion(idx)">
    <CloseX />
  </div>
  <div class="num">{{ idx + 1 }}</div>
  <div class="card">
    <div class="mt-5">
      <h4>Tipe Soal</h4>
    <v-select :disabled="isWatch" class="form-input mt-2" :items="typeQuestion" v-model="n.isEssay" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
            <div>
              <h4>Soal</h4>
    <v-text-field outlined label="" v-model="n.question" class="form-input mt-2"
      :placeholder="`Question ${idx + 1}`" dense></v-text-field>
    </div>
    <div v-if="!n.isEssay">
      <h4>Pilihan Ganda</h4>
      <div v-for="(item2, idx2) in n.choices" :key="idx2" :class="`question-multiple-choice ${n.correct_answer.includes(idx2) ? 'question-true' : ''}`">
        <div style="cursor: pointer; margin-top: 10px" @click="toggleCorrectAnswer(idx, idx2)">
          <SuccessIcon :width="30" />
        </div>
        <span class="mt-4">{{ multiple_choice_option[idx2] }}. </span>
        <v-text-field outlined dense v-model="item2.description" class="form-input mt-2 no-space"></v-text-field>
        <div style="cursor: pointer" @click="onRemoveChoice(idx, idx2)">
          <CloseX />
        </div>
      </div>
      <p>Pilihan Lain</p>
            <div class="spacing-switch">
              <v-switch v-model="n.isOtherChoice" color="primary" hide-details style="margin-top: -5px"></v-switch>
            </div>
      <!-- Add Choice Button -->
      <div class="add-button" @click="onAddChoice(idx)">
        <div class="line"></div>
        <div class="button">+ Tambah Pilihan</div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</div>

            <div class="add-button" @click="addQuestion" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Pertanyaan</div>
            <div class="line"></div>
          </div>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Benefit Event
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="list-event" v-for="(item, idx) in form.benefits" :key="idx" style="margin-left: 0px;">
            <div class="session-nb-3">
              <v-text-field :disabled="isWatch" outlined v-model="form.benefits[idx]" class="form-input mt-2 no-space"
                dense clearable></v-text-field>
              <div @click="form.benefits.splice(idx, 1)" style="cursor: pointer">
                <trash :width="38" :height="38" />
              </div>
            </div>
          </div>
          <div class="add-button" @click="form.benefits.push('')" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Benefit</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="Simpan" width="260px" @click="onSubmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import AddIcon from "@/components/icons/Add.vue";
import Trash from "@/components/icons/Trash.vue";
import Strip from "@/components/icons/Strip.vue";
import Button from "@/components/Button.vue";
import { VueEditor } from "vue2-editor";
import Popup from "@/components/others/Popup.vue";
import Arrow from '@/components/icons/Arrow.vue';
import ImageModal from "@/components/ImageModal.vue";
import TrashIcon from "@/components/icons/Trash.vue";
import SuccessIcon from "@/components/icons/Success.vue";
import CloseX from "@/components/icons/CloseX.vue";

import moment from "moment";

export default {
  name: "FormBlog",
  components: {
    Button,
    Loading,
    AddIcon,
    Strip,
    Trash,
    VueEditor,
    Popup,
    Arrow,
    ImageModal,
    CloseX,
    SuccessIcon,
    TrashIcon
  },
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      multiple_choice_option: this.$helpers.multipleChoices(),
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      imgModel: {
        desktop: {
          thumbnail: null,
          product_detail: null,
          purchase_detail: null,
        },
        mobile: {
          thumbnail: null,
          product_detail: null,
          purchase_detail: null,
        }
      },
      methods: [
        {
          name: "Daring (Online via Zoom)",
          value: "Online",
        },
        {
          name: "Luring (Offline in office)",
          value: "Offline",
        },
      ],
      levelList: [
        {
          name: "Semua Level",
          value: "Semua Level",
        },
        {
          name: "Dasar",
          value: "Dasar",
        },
        {
          name: "Menengah",
          value: "Menengah",
        },
        {
          name: "Profesional",
          value: "Profesional",
        },
      ],
      typeLimitEvent: [
        {
          name: "Tidak Terbatas",
          value: false,
        },
        {
          name: "Terbatas",
          value: true,
        },
      ],
      listSessionSchedule: [
        {
          name: "Hanya 1 Sesi",
          value: 0,
        },
        {
          name: "Lebih dari 1 Sesi Beruntun",
          value: 1,
        },
        {
          name: "Lebih dari 1 Sesi Acak",
          value: 2,
        },
      ],
      lmsConfirmList: [
        {
          name: "Support",
          value: true,
        },
        {
          name: "Manual",
          value: false,
        },
      ],
      typeTiket: [
        {
          name: "Berbayar",
          value: true,
        },
        {
          name: "Gratis",
          value: false,
        },
      ],
      isCurationList: [
        {
          name: "Support",
          value: true,
        },
        {
          name: "Tidak",
          value: false,
        },
      ],
      typeQuestion: [
        {
          name: "Pilihan Ganda",
          value: false,
        },
        {
          name: "Essay",
          value: true,
        },
      ],
      limitOfEvent: false,
      sessionSchedule: 0,
      instructors: [],
      formInstructor: {
        name: "",
        image_url: "",
        position: "",
        institutional_origin: "",
        description: "",
        cv_url: "",
        linkedin_url: "",
      },
      classLms: {
        instruction_final_project: "",
        total_quiz: 0,
        rubrik_url: "",
        instruction_tpm: [],
        materials_link: [],
        record_list: []
      },
      formClass: {
        class: {
          consultancy_link: "",
          consultancy_schedule: "",
          group_chat_link: "",
          material: "",
          prefix_code: "",
          start_consultation_date: "",
          end_consultation_date: "",
          learning_link: "",
          collaboration_feat: "", // empty
          type_class: "", // empty
          type: "",// empty
          class_category_id: 8, // category of Mini Bootcamp,
          class_sub_category_id: 4, // sub category of Mini Bootcamp
        },
        class_detail: {
          class_id: 0,
          hours: "",
          days: "",
          learning_objective: "",
          learning_info_list: [], // empty
          why_info_list: [], // empty
          requirements: [
            { name: "Peserta memiliki gawai untuk pelaksanaan kelas yang terdiri dari smartphone atau laptop" },
            { name: "Peserta harus mengakses kelas melalui LMS HiColleagues" },
            { name: "Peserta wajib datang di seluruh pertemuan dan mengikuti setiap rangkaian pelatihan, termasuk evaluasi berupa test maupun unjuk keterampilan" },
            { name: "Memiliki akses internet yang baik untuk mendukung proses pembelajaran" },
            { name: "Peserta tidak buta huruf" },
            { name: "Pria dan wanita dengan rentang usia 18 - 57 tahun" },
            { name: "Minimal pendidikan dari peserta adalah SMA sederajat" },
          ],
          summary_syllabus: [], // empty
          skkni: "", // empty
          learning_method: "Metode ajar yang digunakan adalah ceramah interaktif melalui webinar, penugasan, dan bahan bacaan dengan penugasan praktek",
          special_purpose: "", // empty
          list_competency: [], // empty
          target_audience: [],
        }
      },
      form: {
        name: "",
        caption: "",
        description: "",
        category_id: 0,
        price: 0,
        batch: 1,
        slug_name: "",
        level: "",
        is_lms_compatible: false,
        total_session: 1,
        day_session: "",
        method: "Online",
        isSyllabuses: false,
        syllabuses: [],
        learning_url: "",
        benefits: [""],
        instructors: [""],
        label_discount: "",
        question: [],
        is_curation: false,
        class_id: 0,
        participant_quota: 0,
        session_schedules: [
          { date_start: "", date_end: "", start_time: "", end_time: "" },
        ],
        img_urls: {
          desktop: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
          mobile: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
        },
        is_event_paid: false,
        registration_close_date: "",
        discount_price: 0,
        start_discount_date: "",
        end_discount_date: "",
        is_active: true,
        session_schedule_type: "",
        location_name: "",
        location_address: "",
        location_map: "",
      },
    };
  },

  watch: {
    limitOfEvent(e) {
      if (e) {
        this.form.participant_quota = 1;
      } else {
        this.form.participant_quota = 0;
      }
    }
  },
  computed: {
    validData() {
      if (this.form.name && this.form.description) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async addNewInsturctor() {
      this.form.instructors.push("");
    },
    addSessionSchedlue() {
      this.form.session_schedules.push({
        date_start: "",
        date_end: "",
        start_time: "",
        end_time: "",
      });
    },
    addListMateri() {
      this.classLms.materials_link.push({
        iteration: this.classLms.materials_link.length + 1,
        description: "",
      });
    },
    addRecordList() {
      this.classLms.record_list.push({
        iteration: this.classLms.record_list.length + 1,
        description: "",
      });
    },
    addInstructionTpm() {
      this.classLms.instruction_tpm.push({
        iteration: this.classLms.instruction_tpm.length + 1,
        description: "",
      });
    },
    removeQuestion(idx) {
      this.form.question.splice(idx, 1);
    },
    addQuestion() {
      this.form.question.push({
        question: "",
        isEssay: false,
        isOtherChoice: false,
        choices: [],
        correct_answer: [],
      });
    },
    toggleCorrectAnswer(questionIndex, choiceIndex) {
  const question = this.form.question[questionIndex];
  if (question.correct_answer.includes(choiceIndex)) {
    question.correct_answer = question.correct_answer.filter(idx => idx !== choiceIndex);
  } else {
    question.correct_answer.push(choiceIndex);
  }
},
  onAddChoice(questionIndex) {
  this.form.question[questionIndex].choices.push({ description: "" });
},
onRemoveChoice(questionIndex, choiceIndex) {
  this.form.question[questionIndex].choices.splice(choiceIndex, 1);
},
    onRemoveIntructor(idx) {
      this.form.instructors.splice(idx, 1);
    },
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataEvent();
    },

    async getIntructure() {
      const resp = await this.$crm_http.get("/v1/instructor");
      this.instructors = resp.data.data.instructors;
    },

    async getDataEvent() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/event/" + this.id);
        const data = resp.data.data.event;
        if (data.participant_quota) {
          this.limitOfEvent = true
        }
        this.form = {
          ...data,
          session_schedules: data.session_schedules ?? [{
            date_start: "",
            date_end: "",
            start_time: "",
            end_time: ""
          }],
          img_urls: Object.keys(data.img_urls).length
            ? data.img_urls
            : {
              desktop: {
                thumbnail: "",
                product_detail: "",
                purchase_detail: "",
              },
              mobile: {
                thumbnail: "",
                product_detail: "",
                purchase_detail: "",
              },
            },
          is_event_paid: data.price ? true : false,
          instructors: data.instructors.map((item) => item.id),
          registration_close_date: moment(data.registration_close_date).format("YYYY-MM-DDThh:mm"),
          start_discount_date: moment(data.start_discount_date).format("YYYY-MM-DDThh:mm"),
          end_discount_date: moment(data.end_discount_date).format("YYYY-MM-DDThh:mm")
        };
        this.sessionSchedule = this.listSessionSchedule.find((item) => item.name === data.session_schedule_type)?.value
        if (this.form.syllabuses.length) {
          this.form.isSyllabuses = true
        }
        if (this.form.is_lms_compatible && this.form.class_id) {
          await this.getClassLms(this.form.class_id)
          await this.getDataClass(this.form.class_id)
        }
        setTimeout(() => {
          this.form.participant_quota = data.participant_quota
          this.isLoading = false;
        }, 1000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async getClassLms(classId) {
      try {
        const resp = await this.$lms_http.get(`/v1/class/${classId}`);
        this.classLms = resp.data.data.class;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getDataClass(classId) {
      try {
        const resp = await this.$crm_http.get(`/v1/class/${classId}`);
        const data = resp.data.data;
        this.formClass = {
          class: {
            ...data.class,
            instructor_id: data.class.instructor.ID,
            class_category_id: data.class.class_category.ID,
            class_sub_category_id: data.class.class_sub_category.ID,
            start_time: data.class.class_detail.hours.split(" - ")[0],
            end_time: data.class.class_detail.hours
              .split(" - ")[1]
              .replace(" WIB", ""),
            start_consultation_date: this.$helpers.getUniqDate(
              data.class.consultancy_schedule.split(" - ")[0], "YYYY-MM-DDThh:mm:ss"),
            end_consultation_date: this.$helpers.getUniqDate(
              data.class.consultancy_schedule.split(" - ")[1], "YYYY-MM-DDThh:mm:ss"),
            registration_close_date: moment(data.class.registration_close_date).format("YYYY-MM-DD")
          },
          class_detail: {
            ...data.class.class_detail,
            start_discount_date: this.$helpers.getUniqDate(data.class.class_detail.start_discount_date, "YYYY-MM-DDThh:mm"),
            end_discount_date: this.$helpers.getUniqDate(data.class.class_detail.end_discount_date, "YYYY-MM-DDThh:mm"),
            private_start_discount_date: this.$helpers.getUniqDate(data.class.class_detail.private_start_discount_date, "YYYY-MM-DDThh:mm"),
            private_end_discount_date: this.$helpers.getUniqDate(data.class.class_detail.private_end_discount_date, "YYYY-MM-DDThh:mm"),
            benefits_req: data.class.class_detail.benefits
              ? data.class.class_detail.benefits
              : [],
            summary_syllabus: data.class.class_detail.summary_syllabus
              ? data.class.class_detail.summary_syllabus
              : [],
          },
        };
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false
      }
    },
    async uploadImage(imageFile) {
      const imageForm = new FormData();
      imageForm.append("attachment", imageFile);
      try {
        const response = await this.$crm_http.post("/v1/upload-file", imageForm);
        return response.data.data.upload_image_response.url_w_query;
      } catch (error) {
        console.error("Failed to upload image:", error);
        return null;
      }
    },
    addListRequirements() {
      this.formClass.class_detail.requirements.push({
        name: ""
      })
    },
    mapFormToClassForm() {
      const syllabuses = []
      this.form.syllabuses.forEach((item, idx) => {
        syllabuses.push({
          description: `<ul>${item
            .map((item2) => `<li>${item2}</li>`)
            .join("")}</ul>`,
          session: "Pertemuan " + (idx + 1),
        });
      });
      if (!this.formClass.class.start_consultation_date) this.formClass.class.start_consultation_date = moment(new Date()).format("YYYY-MM-DD")
      if (!this.formClass.class.end_consultation_date) this.formClass.class.end_consultation_date = moment(new Date()).format("YYYY-MM-DD")
      const classForm = {
        class: {
          name: this.form.name,
          image_url: this.form.img_urls.desktop?.product_detail || "",
          description: this.form.description,
          prefix_code: this.formClass.class.prefix_code,
          meta_description: this.form.description,
          class_category_id: this.formClass.class.class_category_id,
          level: this.form.level,
          class_sub_category_id: this.form.category_id,
          slug_name: this.form.slug_name,
          thumbnail_url: this.form.img_urls.desktop?.thumbnail || "",
          small_image_url: this.form.img_urls.mobile?.thumbnail || "",
          learning_link: this.form.learning_url,
          consultancy_link: this.formClass.class.consultancy_link,
          consultancy_schedule: this.$helpers.getUniqDate(this.formClass.class.start_consultation_date, "YYYY-MM-DDThh:mm") + " - " + this.$helpers.getUniqDate(this.formClass.class.end_consultation_date, "YYYY-MM-DDThh:mm"),
          group_chat_link: this.formClass.class.group_chat_link,
          instructor_id: Number(this.form.instructors[0]) || 0,
          material: this.formClass.class.material,
          method: this.form.method,
          total_audience: Number(this.form.participant_quota),
          start_time: this.form.session_schedules[0]?.start_time || "",
          end_time: this.form.session_schedules[0]?.end_time || "",
          session_schedule: this.form.session_schedules,
          is_recommend: false,
          start_consultation_date: this.formClass.class.start_consultation_date,
          end_consultation_date: this.formClass.class.end_consultation_date,
          collaboration_feat: this.formClass.class.collaboration_feat,
          type_class: this.formClass.class.type_class,
          type: this.formClass.class.type,
          registration_close_date: this.form.registration_close_date,
        },
        class_detail: {
          class_id: 0,
          hours: `${this.form.session_schedules[0].start_time} - ${this.form.session_schedules[0].end_time} WIB`,
          batch_id: this.form.batch,
          price: Number(this.form.price) || 1,
          discount_price: Number(this.form.discount_price),
          private_price: Number(this.form.price) || 1,
          private_discount_price: Number(this.form.discount_price),
          session_total: this.form.total_session,
          days: this.formClass.class_detail.days,
          start_discount_date: this.form.start_discount_date,
          end_discount_date: this.form.end_discount_date,
          private_start_discount_date: this.form.start_discount_date,
          private_end_discount_date: this.form.end_discount_date,
          is_discount: Number(this.form.discount_price) > 0,
          learning_objective: this.formClass.class_detail.learning_objective,
          benefits_req: this.form.benefits,
          label_discount: this.form.label_discount,
          learning_info_list: this.formClass.class_detail.learning_info_list,
          why_info_list: this.formClass.class_detail.why_info_list,
          requirements: this.formClass.class_detail.requirements,
          summary_syllabus: this.formClass.class_detail.summary_syllabus,
          skkni: this.formClass.class_detail.skkni,
          learning_method: this.formClass.class_detail.learning_method,
          special_purpose: this.formClass.class_detail.special_purpose,
          list_competency: this.formClass.class_detail.list_competency,
          syllabuses: syllabuses,
          target_audience: this.formClass.class_detail.target_audience,
        },
      };
      const classLmsReq = {
        class_id: this.form.class_id,
        name: classForm.class.name,
        level: classForm.class.level,
        image_url: classForm.class.image_url,
        category: classForm.class.class_category_id,
        slug_name: classForm.class.slug_name,
        learning_link: this.form.learning_url,
        group_chat_link: classForm.class.group_chat_link,
        material_link: classForm.class.material,
        learning_method: classForm.class.method,
        final_project_link: "",
        days: classForm.class_detail.days,
        hours: `${classForm.class.start_time} - ${classForm.class.end_time} WIB`,
        syllabuses: classForm.class_detail.syllabuses,
        instruction_final_project: this.classLms.instruction_final_project,
        materials_link: this.classLms.materials_link,
        total_quiz: Number(this.classLms.total_quiz),
        total_tpm: this.classLms.instruction_tpm.length,
        rubrik_url: this.classLms.rubrik_url,
        instruction_tpm: this.classLms.instruction_tpm,
        prefix_code: classForm.class.prefix_code,
        skkni: classForm.class_detail.skkni,
        record_list: this.classLms.record_list,
        list_competency: classForm.class_detail.list_competency,
      };
      return [classForm, classLmsReq];
    },
    async handleImageUpload() {
      for (const [device, images] of Object.entries(this.imgModel)) {
        for (const [key, imageFile] of Object.entries(images)) {
          if (imageFile) {
            const imageUrl = await this.uploadImage(imageFile);
            if (imageUrl) {
              this.form.img_urls[device][key] = imageUrl;
            }
          }
        }
      }
    },
    async onSubmit() {
      if (!this.form.is_event_paid) {
        this.form.price = 0
        this.form.discount_price = 0
      }
      this.isLoading = true;
      await this.handleImageUpload()
      if (!this.form.start_discount_date) this.form.start_discount_date = moment(new Date()).format("YYYY-MM-DD")
      if (!this.form.end_discount_date) this.form.end_discount_date = moment(new Date()).format("YYYY-MM-DD")
      try {
        if (this.form.is_lms_compatible) {
          const [cls, clsLMS] = this.mapFormToClassForm()
          if (this.form.class_id) {
            await this.$crm_http.put("/v1/class/" + this.form.class_id, cls);
          } else {
            const resp = await this.$crm_http.post("/v1/class", cls);
            clsLMS.class_id = resp.data.data;
            this.form.class_id = resp.data.data
          }
          await this.$lms_http.post("/v1/class", clsLMS);
        }
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
      try {
        this.form = {
          ...this.form,
          session_schedule_type: this.listSessionSchedule.find((item) => item.value === this.sessionSchedule).name,
          category_id: Number(this.$route.params.id_category),
          price: Number(this.form.price),
          batch: Number(this.form.batch),
          discount_price: Number(this.form.discount_price),
          participant_quota: Number(this.form.participant_quota),
          total_session: this.form.session_schedules.length ? this.form.session_schedules.length : 1
        };
        if (this.isEdit) {
          await this.$crm_http.put("/v1/event/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/event", this.form);
        }
        setTimeout(() => {
          this.isLoading = false
          this.$router.push(`/event-category/${this.$route.params.id_category}/event`);
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    checkScheduleType() {
      if (this.sessionSchedule === 0) {
        this.form.session_schedules[0].date_end = ''
        this.form.session_schedules.splice(1)
      } else if (this.sessionSchedule === 1) {
        this.form.session_schedules.splice(1)
      } else {
        this.form.session_schedules = this.form.session_schedules.map(item => ({
          ...item,
          date_end: ""
        }))
      }
    },
    addSubSession(i) {
      if (i !== null) {
        this.form.syllabuses[i].push("");
        return;
      }
      this.form.syllabuses.push([""]);
      this.form.total_session = this.form.syllabuses.length;
    },
    onRemoveEvent(i, i2) {
      this.form.syllabuses[i].splice(i2, 1);
      if (this.form.syllabuses[i].length === 0) {
        this.form.syllabuses.splice(i, 1);
        this.form.total_session = this.form.syllabuses.length;
      }
    },
  },
  mounted() {
    this.getIntructure();
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}


.card-form-head {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.card-form-head-2 {
  display: flex;
  margin-left: -35px;
  margin-right: -20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.card-form-head-2 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #36373f;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.card-form-head h1 {
  font-weight: 700;
  color: #36373f;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.card-form-head-bdy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.card-form-head-bdy p {
  color: #575966;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #162ca2;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.form-ctn {
  padding: 0 40px;
}

.cart-form {
  position: relative;
  margin: 40px 0;
  border-radius: 30px;
  padding: 30px 40px;
  background-color: #ffffff;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.no-space {
  margin-bottom: -20px;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}

.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}

.icon-plus {
  position: absolute;
  bottom: 0px;
  left: -56px;
  cursor: pointer;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}

.info-head {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 30px;
}

.spacing-switch {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.container-date {
  display: flex;
  gap: 15px;
}

.container-date-left {
  width: 50%;
}

.container-date-rigth {
  width: 50%;
}

.session-numb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session-nb-3 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.icon-plus-bc {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f6fa;
}
.question {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.question-remove {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
}

.question-multiple-choice {
  display: flex;
  gap: 20px;
}

.question-true .v-text-field--outlined>>>fieldset {
  border: 3px solid #01A368;
}

.num {
  background-color: #ffde59;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  font-weight: 500;
  height: 45px;
  border-radius: 10px ;
}

.card {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0 20px 20px 20px;
  padding: 20px 25px;
}

.card .title {
  font-size: medium !important;
  line-height: 27px;
  color: #36373f;
  font-weight: bold;
  font-family: "Poppins" !important;
}

.answer {
  background-color: transparent;
  border-radius: 15px;
  padding: 8px;
  font-weight: bold !important;
}

.answer-selected {
  background-color: #f1f6ff;
  border-radius: 15px;
  padding: 8px;
  font-weight: bold !important;
}

.btn-submit {
  margin: 0;
  font-size: 22px;
  max-width: 400px;
  border-radius: 18px !important;
  width: 100%;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

</style>
