<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1 style="text-align: center; margin: 40px 0">{{ name }}</h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Nama</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.name"
            class="form-input"
            placeholder="Nama"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Partner Code</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="partnerCodes"
            :item-text="'name'"
            :item-value="'value'"
            placeholder="Partner Code"
            v-model="form.partner_code"
            solo
          ></v-select>
        </div>
        <div class="content-form">
          <p>Tipe voucher</p>
          <v-select
            :disabled="isWatch"
            class="form-input"
            :items="categoryCodeList"
            :item-text="'name'"
            :item-value="'value'"
            placeholder="Select"
            v-model="form.category_code"
            @change="getDataCategory(true)"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Kategori</p>
          <v-select
            :disabled="isWatch || !form.category_code"
            class="form-input"
            :items="classCategoryList"
            placeholder="Select"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.class_category_id"
            @change="getDataClass(true)"
            solo
          >
          </v-select>
        </div>
        <div class="content-form">
          <p>Program</p>
          <v-select
            class="form-input"
            :items="classes"
            placeholder="Select"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.class_id"
            solo
            :disabled="isWatch || !form.class_category_id"
          >
          </v-select>
        </div> 
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <div class="content-form">
          <p>Kode Voucher</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.voucher_code"
            class="form-input"
            placeholder="Kode Voucher"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Nominal Diskon</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.discount"
            class="form-input"
            placeholder="Nominal Diskon"
            type="number"
            dense
            clearable
            @input="
              () => {
                if (form.discount < 0) {
                  form.discount = 0;
                }
              }
            "
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Stock</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.stock"
            @input="
              () => {
                if (form.stock < 0) {
                  form.stock = 1;
                }
              }
            "
            class="form-input"
            placeholder="Stock"
            type="number"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="content-form">
          <p>Durasi Aktif Voucher</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="pt-2"> Mulai </v-col>
                <v-col cols="7">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    v-model="form.start_date"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="5" class="pt-2"> Selesai </v-col>
                <v-col cols="7">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    v-model="form.end_date"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form">
          <p>Jadwal Kelas</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="4" class="pt-2"> Mulai </v-col>
                <v-col cols="7">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    v-model="form.schedule_start"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="5" class="pt-2"> Selesai </v-col>
                <v-col cols="7">
                  <v-text-field
                    :disabled="isWatch"
                    outlined
                    type="date"
                    v-model="form.schedule_end"
                    class="form-input"
                    placeholder="00:00"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form">
          <p>Catatan</p>
          <v-textarea
            placeholder="Caption"
            :disabled="isWatch"
            outlined
            rows="3"
            v-model="form.description"
            clearable
            class="form-input"
          ></v-textarea>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="PUBLISH"
            width="260px"
            @click="onSumbmit"
            :disabled="!validData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import Button from "../../../components/Button.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "FormBlog",
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      statuses: [
        {name: "Aktif", value: true},
        {name: "Non Aktif", value: false},
      ],
      partnerCodes: [
        {
          name: "General",
          value: "TESTING"
        },
        {
          name: "Pijar",
          value: "DP_PJR"
        },
        {
          name: "Tokopedia",
          value: "DP_TOKPED"
        },
        {
          name: "Pintar",
          value: "DP_PTR"
        },
      ],
      category: [
        {
          name: "Public Training",
          value: "public_training",
        },
        {
          name: "Bootcamp",
          value: "bootcamp",
        },
        {
          name: "Event",
          value: "event",
        },
      ],
      classes: [],
      classCategoryList: [],
      categoryCodeList: [
        {name: "General Class", value: "GENERAL"},
        {name: "Event", value: "EVENT"},
      ],
      categories: [
          {name: "Pijar", value: "PIJAR"},
          {name: "Pintar", value: "PINTAR"},
          {name: "Tokopedia", value: "TOKOPEDIA"},
          {name: "Bukalapak", value: "BUKALAPAK"},
          {name: "Testing Schema", value: "TESTING"},
        ],
      form: {
        name: "",
        image_url: "",
        partner_code: "",
        voucher_code: "",
        discount: 0,
        stock: 1,
        class_id: 0,
        class_category_id: 0,
        category_code: "",
        start_date: "",
        end_date: "",
        schedule_start: "",
        schedule_end: "",
        schedule_start_end: "",
        description: "",
        
      },
    };
  },
  components: {
    Button,
    Loading,
    Popup
  },
  computed: {
    validData() {
      return (
        this.form.name &&
        // this.form.category &&
        this.form.voucher_code &&
        this.form.discount &&
        this.form.start_date &&
        this.form.end_date &&
        this.form.description
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getVoucher();
      
    },
    async getVoucher() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/voucher/" + this.id);
        const data = resp.data.data.voucher;
        const schedule = data.schedule_start_end.split(" - ")
        this.form = {
          ...data,
          start_date: moment(data.start_date).format("YYYY-MM-DD"),
          end_date: moment(data.end_date).format("YYYY-MM-DD"),
          schedule_start: moment(new Date(schedule[0])).format("YYYY-MM-DD"),
          schedule_end: moment(new Date(schedule[1])).format("YYYY-MM-DD"),
        };
        this.isLoading = false;
        this.getDataClass();
        this.getDataCategory();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async getDataCategory(riset) {
      if (riset) {
        this.form.class_category_id = 0
        this.form.class_id = 0
      }
      this.classCategoryList = []
      this.isLoading = true;
      try {
        if (this.form.category_code === 'EVENT') {
          const resp = await this.$crm_http.get("/v1/event-category");
          const arrayData = resp.data.data?.event_categories?.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          this.classCategoryList.push(...arrayData); 
        } else  {
          const resp = await this.$crm_http.get("/v1/class-category");
          const arrayData = resp.data.data?.class_categories?.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          this.classCategoryList.push(...arrayData); 
        }
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async getDataClass(riset) {
      if (riset) {
        this.form.class_id = 0
      }
      this.classes = []
      this.isLoading = true;
      try {
        if (this.form.category_code === 'EVENT') {
          const resp = await this.$crm_http.get(`/v1/event?category=${this.form.class_category_id}&page=1&per_page=100`);
          const arrayData = resp.data.data?.events?.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          this.classes.push(...arrayData); 
        } else {
          const resp = await this.$crm_http.get(`/v1/class/category/${this.form.class_category_id}?page=1&per_page=100`);
          const arrayData = resp.data.data?.classes?.map((item) => ({
            name: item.name,
            value: item.id,
          }));
          this.classes.push(...arrayData);
        }
        this.isLoading = false;
      } catch (err) {
        this.message = err.response?.data?.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/voucher/" + this.id, {
            ...this.form,
            discount: Number(this.form.discount),
            stock: Number(this.form.stock),
            class_id: Number(this.form.class_id),
            class_category_id: Number(this.form.class_category_id),
            category_code: this.form.category_code,
            schedule_start_end: `${moment(this.form.schedule_start).format(
              "DD MMM YYYY"
            )} - ${moment(this.form.schedule_end).format("DD MMM YYYY")}`,
          });
        } else {
          await this.$crm_http.post("/v1/voucher", {
            ...this.form,
            discount: Number(this.form.discount),
            stock: Number(this.form.stock),
            category_code: this.form.category_code,
            schedule_start_end: `${moment(this.form.schedule_start).format(
              "DD MMM YYYY"
            )} - ${moment(this.form.schedule_end).format("DD MMM YYYY")}`,
          });
        }
        setTimeout(() => {
          this.$router.push("/voucher");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  background-color: white;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: absolute;
  top: 0;
  right: -50px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
.form-ctn {
  padding: 0 40px;
}
.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
