<template>
  <div class="container-custom" style="margin-left: 0px">
    <Loading v-if="isLoading" />
    <Popup :message="errorMessage" isError v-if="isShowPopup" @close="
      () => {
        isShowPopup = !isShowPopup;
      }
    " />
    <div class="header">
      <h1>{{ queryParams.c }}</h1>
      <div v-if="is_prakerja" class="prakerja-icon">
        <img src="/img/icons/HiColl_Prakerje.png" alt="Prakerja" />
      </div>
    </div>
    <LineDivider height="1px" />
    <div class="card" style="width: 80%; margin: auto; border-radius: 20px;">
      <h2 class="mb-4">Setting question</h2>
      <v-row>
        <v-col cols="3">
          <span>Type Question</span>
          <v-select class="form-input mt-2" v-model="questionType" :items="questionTypeOption" :item-text="'name'"
            :item-value="'value'" solo></v-select>
        </v-col>
      </v-row>
      <span>Dynamic Score</span>
      <v-switch v-model="dynamicScore" color="primary" hide-details style="margin-top: -5px"></v-switch>
    </div>
    <div class="questions-list">
      <div class="question" v-for="(n, idx) in question_sets" :key="idx">
        <div class="question-remove" @click="removeQuestion(idx)">
          <CloseX />
        </div>
        <input type="file" :ref="`file_upload-${idx}`" :accept="$helpers.applyFormatFile()"
          @change="(e) => uploadFile(e, idx)" style="display: none" />
        <div class="num">{{ n.number }}</div>
        <div class="card">
          <div class="form-change" v-if="!n.attached_file">
            <div id="drop-area" class="form-upload-file" @dragleave="onDragCondition"
              @dragover.prevent="onDragCondition" @drop.prevent="onDrop($event, idx)" @click="clickUpload(idx)">
              <Upload />
              <span class="upload-title required">Unggah Dokumen Resume
                <RequiredHover />
              </span>
              <span class="upload-description">
                Pilih atau tarik file dalam format JPEG/PNG/PDF max 2 MB
              </span>
            </div>
          </div>
          <div v-else>
            <img :src="n.attached_file" :alt="`Resource ` + idx" v-if="n.attached_file_type === 'image'"
              class="question-img" title="click to see detail" />
            <div class="upload-bar">
              <div class="reupload">
                <span @click="clickUpload(idx)">Unggah File Lain</span>
                <bullet :width="4" :height="4" strokeColor="#7B7E8C" />
                <span @click="removeImage(idx)">Hapus Gambar</span>
              </div>
            </div>
          </div>
          <v-text-field outlined label="Soal" v-model="n.question" class="form-input mt-8"
            :placeholder="`Question ${idx + 1}`" dense></v-text-field>
          <div v-if="questionType === 'mutiple choice'">
            <div class="d-flex" style="justify-content: space-between;">
              <h4>Pilihan Ganda</h4>
              <div style="width: 12%;">
                <v-text-field v-if="!dynamicScore" outlined label="Point Question (dapat berbentuk pecahan)"
                  v-model="n.point" class="form-input" type="number" dense></v-text-field>
              </div>
            </div>
            <div v-for="(item2, idx2) in n.choices" :key="idx2" :class="`question-multiple-choice ${n.correct_answer == idx2 ? 'question-true' : ''
              }`">
              <div style="cursor: pointer; margin-top: 10px" @click="setChoiceTrue(idx, idx2)"
                v-if="n.correct_answer != idx2">
                <SuccessIcon :width="30" />
              </div>
              <span class="mt-4">{{ multiple_choice_option[idx2] }}. </span>
              <v-text-field outlined dense v-model="item2.description" class="form-input mt-2 no-space"></v-text-field>
              <div style="cursor: pointer" @click="onRemoveChoice(idx, idx2)">
                <CloseX />
              </div>
            </div>

            <div class="add-button" @click="onAddChoice(idx)">
              <div class="line"></div>
              <div class="button">+ Tambah Pilihan</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <v-btn class="text-none" color="primary" @click="addQuestion">
        TAMBAH SOAL
      </v-btn>
      <br />
      <span>Pastikan semua soal dan jawaban sesuai</span>
      <Button name="SIMPAN" width="300px" @click="simpanSoal" />
    </div>
  </div>
</template>

<script>
import CloseX from "@/components/icons/CloseX.vue";
import Button from "@/components/Button.vue";
import LineDivider from "@/components/others/LineDivider.vue";
import Popup from "@/components/others/Popup.vue";
import Bullet from "@/components/icons/Bullet.vue";
import RequiredHover from "@/components/icons/RequiredHover.vue";
import Upload from "@/components/icons/Upload.vue";
import SuccessIcon from "@/components/icons/Success.vue";
import Loading from "@/components/ilustration/Loading.vue";

export default {
  components: {
    LineDivider,
    Popup,
    Bullet,
    RequiredHover,
    Upload,
    CloseX,
    Button,
    SuccessIcon,
    Loading,
  },
  data() {
    return {
      question_id: this.$route.params.id,
      multiple_choice_option: this.$helpers.multipleChoices(),
      question_sets: [],
      group: 0,
      class_schedule: {},
      is_prakerja: false,
      isLoading: false,
      dynamicScore: true,
      questionType: "mutiple choice",
      questionTypeOption: [
        {
          name: "Multiple Coice",
          value: "mutiple choice",
        },
        {
          name: "Essay",
          value: "essay",
        },
      ],
      queryParams: {
        c: "",
        g: 0,
      },
      form: {
        point: 5,
      },
      isModal: false,
      detailImg: "",
      isShowPopup: false,
      errorMessage: "",
    };
  },
  methods: {
    async getQuestion() {
      this.isLoading = true;
      await this.$lms_http
        .get(
          `/v1/question-set-instructor?class_id=${this.$route.params.id_class}&group=${this.queryParams.g}&category_question_set=${this.queryParams.c}`
        )
        .then((response) => {
          let { questions_sets } = response.data.data;
          questions_sets.forEach((element, idx) => {
            this.question_sets.push({
              grouping: element.grouping,
              class_id: element.class_id,
              category_question_set: element.category_question_set,
              question: element.question,
              question_type: element.question_type,
              choices: element.choices,
              correct_answer: this.multiple_choice_option.indexOf(
                element.correct_answer
              ),
              point: element.point,
              attached_file: element.attached_file,
              attached_file_type: element.attached_file_type,
              number: idx + 1,
            });
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    async uploadDataFile(file, idx) {
      const formData = new FormData();
      formData.append("attachment", file);
      const res_upload = await this.$crm_http.post(
        "/v1/upload-file",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      this.question_sets[idx].attached_file =
        res_upload.data.data.upload_image_response.url_w_query;
      this.question_sets[idx].attached_file_type = "image";
    },
    removeImage(idx) {
      this.question_sets[idx].attached_file = "";
      this.question_sets[idx].attached_file_type = "";
    },
    removeQuestion(idx) {
      this.question_sets.splice(idx, 1);
      const data = [...this.question_sets];
      this.question_sets = data.map((item, idx) => ({
        ...item,
        number: idx + 1,
      }));
    },
    async simpanSoal() {
      this.isLoading = true;
      try {
        let totalDistributed = 0; // Untuk melacak total poin yang sudah didistribusikan
        const questionSetsWithPoints = this.question_sets.map((item, index) => {
          let point;
          if (this.dynamicScore) {
            if (this.question_sets.length > 0) {
              if (index < this.question_sets.length - 1) {
                point = Number(parseFloat((100 / this.question_sets.length).toFixed(2)));
                totalDistributed += point;
              } else {
                point = Number(parseFloat((100 - totalDistributed).toFixed(2)));
              }
            } else {
              point = 0;
            }
          } else {
            point = Number(item.point);
          }
          return {
            ...item,
            choices: this.questionType === 'mutiple choice' ? item.choices.map((item2, idx) => ({
              ...item2,
              option: this.multiple_choice_option[idx],
            })) : [],
            correct_answer: this.questionType === 'mutiple choice'
              ? this.multiple_choice_option[item.correct_answer]
              : "",
            point: point,
          };
        });


        await this.$lms_http.post("/v1/question-set-group", {
          class_id: Number(this.$route.params.id_class),
          grouping: Number(this.queryParams.g),
          category_question_set: this.queryParams.c,
          question_group: questionSetsWithPoints,
        });
        this.$router.go(-1);
        this.isLoading = false;
      } catch (err) {
        this.isShowPopup = true;
        this.errorMessage = err.response.data.message;
        this.isLoading = false;
      }
    },
    setChoiceTrue(i1, i2) {
      this.question_sets[i1].correct_answer = i2;
    },
    onRemoveChoice(i1, i2) {
      this.question_sets[i1].choices.splice(i2, 1);
      if (this.question_sets[i1].correct_answer >= this.question_sets[i1].choices.length) {
        this.question_sets[i1].correct_answer = this.question_sets[i1].choices.length - 1;
      }
    },
    onAddChoice(i1) {
      this.question_sets[i1].choices.push({
        option: "",
        description: "",
      });
    },
    addQuestion() {
      this.question_sets.push({
        grouping: this.queryParams.g,
        class_id: this.$route.params.id_class,
        category_question_set: this.queryParams.c,
        question: "",
        question_type: this.questionType,
        choices: [],
        correct_answer: 0,
        point: 0,
        attached_file: "",
        attached_file_type: "",
        number: this.question_sets.length + 1,
      });
    },
    // function support
    getQueryParams() {
      const query = this.$route.query;
      this.queryParams = query;
    },
    clickUpload(i) {
      this.$refs["file_upload-" + i][0].click();
    },
    onDragCondition(event) {
      event.preventDefault();
    },
    onDrop(event, i) {
      this.uploadDataFile(event.dataTransfer.files[0], i);
    },
    uploadFile(event, i) {
      this.uploadDataFile(event.target.files[0], i);
    },
  },
  mounted() {
    this.getQueryParams();
    this.getQuestion();
  },
};
</script>

<style scoped>
.navigation {
  margin-top: -50px;
  margin-left: -50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.navigation span {
  color: #162ca2;
  font-weight: bold;
}

.navigation span:hover {
  cursor: pointer;
}

.navigation span:nth-last-child(1) {
  /* Atur gaya CSS yang berbeda di sini */
  /* Contoh: */
  color: #575966;
}

.navigation span:nth-last-child(1):hover {
  cursor: default;
}

.header {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  padding: 30px 0;
  border-bottom: 1px solid #7b7e8c;
}

.question-img {
  object-fit: contain;
  width: 100%;
  height: 350px;
  margin-bottom: 15px;
  cursor: pointer;
}

.prakerja-icon {
  position: absolute;
  right: 0;
}

.prakerja-icon img {
  height: 48px;
}

.questions-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.question {
  display: flex;
  align-items: flex-start;
  width: 80%;
  position: relative;
}

.question-remove {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.question-multiple-choice {
  display: flex;
  gap: 20px;
}

.question-true .v-text-field--outlined>>>fieldset {
  border: 3px solid #01A368;
}

.num {
  background-color: #ffde59;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  font-weight: 500;
  height: 45px;
  border-radius: 10px 0 0 10px;
}

.card {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0 20px 20px 20px;
  padding: 20px 25px;
}

.card .title {
  font-size: medium !important;
  line-height: 27px;
  color: #36373f;
  font-weight: bold;
  font-family: "Poppins" !important;
}

.answer {
  background-color: transparent;
  border-radius: 15px;
  padding: 8px;
  font-weight: bold !important;
}

.answer-selected {
  background-color: #f1f6ff;
  border-radius: 15px;
  padding: 8px;
  font-weight: bold !important;
}

.btn-submit {
  margin: 0;
  font-size: 22px;
  max-width: 400px;
  border-radius: 18px !important;
  width: 100%;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.form-change {
  width: 100%;
  height: 230px;
  border-radius: 16px;
  border: 2px dashed #acafbf;
}

.form-upload-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-title {
  font-size: 16px;
  font-weight: 600;
  color: #162ca2;
}

.required {
  position: relative;
}

.upload-description {
  font-size: 12px;
  color: #7b7e8c;
  text-align: center;
}

.form-uploaded-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 35px;
}

.uploaded-file-name {
  padding-top: 7px;
  color: #36373f;
  font-size: 14px;
  font-weight: 600;
}

.upload-bar {
  padding-top: 10px;
  width: 100%;
}

.reupload {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-top: 4px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  gap: 6px;
}

.error-message {
  padding-top: 7px;
  font-size: 12px;
  font-weight: 600;
}

.danger {
  color: red;
}

#drop-area.active {
  background-color: #bdc1d89d !important;
}

.button-action {
  display: flex;
  justify-content: right;
  width: 537px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
}

.small-text {
  font-size: 14px;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}
</style>
